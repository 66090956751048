<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="text-center mt-2">
						<h5>{{$t('header-register')}}</h5>
					</div>
					<div class="p-2 mt-4">
						<div class="mb-3 text-center">
							<h6>С помощью сервиса</h6>
							<div class="pt-2">
								<button @click="onAuthProvider(provider.type, providerTypes[provider.type])" class="btn btn-secondary p-0 m-1" :class="['icon-hover-'+provider.icon]" :title="provider.name" v-for="provider in appModel.providers" :key="provider.type">
									<svg width="36" height="36">
										<use :xlink:href="'/assets/images/icons-sprite.svg#'+provider.icon"></use>
									</svg>
								</button>
							</div>
							<div class="mt-4 text-center">{{$t('or')}}</div>
						</div>
						<form @submit.prevent="onRegisterSubmit">
							<div class="mb-3">
								<label class="form-label" for="login">{{$t('label-login')}}</label>
								<input
									type="text"
									class="form-control"
									id="login"
									v-model="login"
									autocomplete="username"
									required
								/>
							</div>
							<div class="mb-3">
								<label class="form-label" for="email">{{$t('label-email')}}</label>
								<input
									type="email"
									class="form-control"
									id="email"
									v-model="email"
									autocomplete="username"
									required
								/>
							</div>
							<div class="mb-3">
								<label class="form-label" for="password1">{{$t('label-password')}}</label>
								<div class="input-group">
									<input
										:type="type"
										class="form-control"
										id="password1"
										v-model="password1"
										autocomplete="new-password"
										required
									/>
									<button
										@click="buttonEye(), (clickedEye = !clickedEye)"
										class="btn btn-outline-secondary shadow-none"
										type="button"
									>
										<font-awesome-icon
											class="eyeButton"
											:icon="clickedEye ? 'eye-slash' : 'eye'"
										>
										</font-awesome-icon>
									</button>
								</div>
							</div>
							<div class="mb-3">
								<label class="form-label" for="password2">{{$t('label-password-repeat')}}</label>
								<div class="input-group">
									<input
										:type="type"
										class="form-control"
										id="password2"
										v-model="password2"
										autocomplete="new-password"
										required
									/>
									<button
										@click="buttonEye(), (clickedEye = !clickedEye)"
										class="btn btn-outline-secondary shadow-none"
										type="button"
									>
										<font-awesome-icon
											class="eyeButton"
											:icon="clickedEye ? 'eye-slash' : 'eye'"
										>
										</font-awesome-icon>
									</button>
								</div>
							</div>
							<div class="mb-3">
								<div class="form-check">
									<input class="form-check-input" type="checkbox" value="1" id="chkPrivacy" v-model="chkPrivacy" required />
									<label class="form-check-label" for="chkPrivacy">Я принимаю условия <a href="/info/terms" target="_blank">Пользовательского соглашения</a></label>
								</div>
							</div>
							<div class="mb-3 text-center">
								<vue-hcaptcha :sitekey="appModel.hcaptcha_sitekey"></vue-hcaptcha>
							</div>
							<div class="mt-3 text-end d-grid">
								<button class="btn btn-lg btn-primary" type="submit" :disabled="btnRegisterDisabled">{{$t('btn-register')}}</button>
							</div>
						</form>
						<div class="alert alert-warning text-center mt-3 mb-0" v-if="error">{{error}}</div>
					</div>
				</div>
			</div>
			<div class="card shadow-none">
				<div class="card-body p-4 text-center">
					{{$t('text-have-account')}} <router-link :to="{path: '/login'}">{{$t('btn-login')}}</router-link>
				</div>
			</div>
			<div class="card shadow-none">
				<div class="card-body p-4 text-center">
					{{$t('text-lost-password')}} <router-link :to="{path: '/recovery-password'}">{{$t('btn-remember-password')}}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { Facebook, Google, Github, Twitter, Vkontakte, Live } from 'universal-social-auth';
import { Discord, Mailru, Twitch, Yandex } from '@/assets/plugins/socials';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Регистрация | byLex Account'});
	},
	data: () => ({
		login: '',
		email: '',
		password1: '',
		password2: '',
		chkPrivacy: false,
		
		clickedEye: false,
		type: 'password',
		
		error: '',
		btnRegisterDisabled: false,
		
		providerTypes: {
			facebook: Facebook,
			google: Google,
			github: Github,
			twitter: Twitter,
			vkontakte: Vkontakte,
			microsoft: Live,
			discord: Discord,
			mailru: Mailru,
			twitch: Twitch,
			yandex: Yandex,
		},
	}),
	methods: {
		async onRegisterSubmit(){
			this.btnRegisterDisabled = true;
			this.error = '';
			
			let hcaptcha = window['hcaptcha'] != null ? window['hcaptcha'].getResponse() : '';
			
			let res = await this.authModel.doRegister({
				login: this.login,
				email: this.email,
				password1: this.password1,
				password2: this.password2,
				captcha: hcaptcha,
				captcha_type: 'hcaptcha',
			});
			
			this.btnRegisterDisabled = false;
			if(res.success){
				this.$router.push('/login');
			} else {
				this.error = res.error;
				window['hcaptcha'].reset();
			}
		},
		
		async onAuthProvider(provider, type){
			this.error = '';
			
			await this.$Oauth.authenticate(provider, type).then(async (response) => {
				if(response.code){
					let res = await this.authModel.socialLogin(provider, response.code);
					if(res.success){
						this.$router.push('/');
					} else {
						this.error = res.error;
					}
				}
			}).catch((err) => {
				console.log(err);
			});
		},
		
		buttonEye() {
			if (this.type === 'password'){
				this.type = 'text';
			} else {
				this.type = 'password';
			}
		},
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		VueHcaptcha,
	},
};
</script>