import { createI18n } from 'vue-i18n';
import ru from '@/locales/ru.json';
import en from '@/locales/en.json';
//import axios from 'axios';

import AuthModel from '@/models/AuthModel.js';

export default class AppModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.providers = [
			{type: 'discord', name: 'Discord', icon: 'discord'},
			{type: 'facebook', name: 'Facebook', icon: 'facebook'},
			{type: 'github', name: 'GitHub', icon: 'github'},
			{type: 'google', name: 'Google', icon: 'google'},
			{type: 'mailru', name: 'Mail.ru', icon: 'mailru'},
			//{type: 'microsoft', name: 'Microsoft Live', icon: 'liveid'},
			//{type: 'steam', name: 'Steam', icon: 'steam'},
			//{type: 'telegram', name: 'Telegram', icon: 'telegram'},
			{type: 'twitch', name: 'Twitch', icon: 'twitch'},
			//{type: 'twitter', name: 'Twitter', icon: 'twitter'},
			{type: 'vkontakte', name: 'ВКонтакте', icon: 'vk'},
			{type: 'yandex', name: 'Яndex', icon: 'yandex'},
		];
		
		this.hcaptcha_sitekey = 'e9c8a7dc-f9ca-437d-bbc5-ba9fa661b8a6';
		
		this.langs = ['ru', 'en'];
		this.langsName = {
			ru: 'Русский',
			en: 'English',
		};
		this.title = '';
		this.i18n = createI18n({
			legacy: false,
			locale: 'ru',
			messages: { ru, en },
		});
		
		this.auth = new AuthModel();
	}
	
	/**
	 * Set page title
	 */
	setPageTitle(title)
	{
		this.title = title;
		document.title = title;
	}
	
	/**
	 * Set app lang
	 */
	setLang(lang = 'ru')
	{
		this.i18n.global.locale = lang;
		//axios.defaults.headers.common['Accept-Language'] = lang;
		document.querySelector('html').setAttribute('lang', lang);
		return lang;
	}
	
	/**
	 * Returns lang
	 */
	getLang()
	{
		//return this.i18n.locale;
		return this.i18n.global.locale;
	}
	
	pageReload()
	{
		document.location.reload();
	}
}
