<template>	
	<div class="row align-items-center justify-content-center">
		<div class="col-md-12 col-lg-10 col-xl-8">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="mt-2">
						<h4>Связаться с администрацией</h4>
					</div>
					<div class="p-2 mt-4">
						<p>По всем вопросам, предложениям, жалобам, просьба обращаться на электронную почту: <a href="mailto:lexin.ucoz.ru@gmail.com">lexin.ucoz.ru@gmail.com</a></p>
						<!--
						<form @submit.prevent="onFeedbackSubmit">
							<div class="mb-3">
								<label for="inpTheme" class="form-label">Тема сообщения</label>
								<select class="form-control" id="inpTheme">
									<option>Общие вопросы</option>
									<option>Ошибка в работе сайта</option>
									<option>Уязвимость на сайте</option>
									<option>Нарушение правил сайта</option>
									<option>Блокировка аккаунта</option>
									<option>Смена логина/почты</option>
									<option>Удаление аккаунта</option>
									<option>Гениальная идея</option>
									<option>Предложение сотрудничества</option>
									<option>Проблемы с почтой</option>
								</select>
							</div>
							<div class="mb-3">
								<label for="inpEmail" class="form-label">Электронная почта</label>
								<input type="email" class="form-control" id="inpEmail" placeholder="name@example.com" required />
							</div>
							<div class="mb-3">
								<label for="inpMessage" class="form-label">Сообщение</label>
								<textarea class="form-control" id="inpMessage" rows="6" required></textarea>
							</div>
							<div class="mb-3">
								<div class="form-check">
									<input class="form-check-input" type="checkbox" value="1" id="chkPrivacy" required />
									<label class="form-check-label" for="chkPrivacy">Даю согласие на <a href="/info/privacy" target="_blank">обработку своих персональных данных</a></label>
								</div>
							</div>
							<div class="mb-3">
								<button type="submit" class="btn btn-primary">Отправить</button>
							</div>
						</form>
						<div class="alert alert-success">Сообщение отправлено! Сохраняйте терпение, в ближайшее время вы получите ответ.</div>
						-->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Обратная связь | byLex Account'});
	},
	data: () => ({
		
	}),
	methods: {
		onFeedbackSubmit(){
			
		},
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		//Navbar,
	},
};
</script>