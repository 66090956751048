<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-12 col-lg-10 col-xl-8">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="mt-2">
						<h4>Политика конфиденциальности</h4>
					</div>
					<div class="mt-4">
						<div class="row">
							<div class="col">
								<p>Настоящий документ «Политика конфиденциальности» (далее – по тексту – «Политика») представляет собой правила использования ИП «byLex» (далее «byLex») Персональной информации Пользователя.</p>
							</div>
							<div class="col text-end">
								<p>ИП «byLex»<br/>Введено в действие 18.12.2022 г</p>
							</div>
						</div>
					</div>
					<div class="mt-5">
						<p>Проставляя отметку в поле «Я согласен», сопровождающемся ссылкой на настоящую Политику, вы (далее также — Пользователь) свободно, своей волей и в своих интересах даете свое письменное согласие на обработку Персональной информации в порядке и на условиях, установленных настоящей Политикой.</p>
						<ol>
							<li>
								<h5 class="mb-4 text-dark" id="1">Общие положения</h5>
								<ol>
									<li id="2.11">Настоящая Политика является неотъемлемой частью Пользовательского соглашения (далее — «Соглашение»), размещенного и/или доступного в сети Интернет по адресу: <a href="https://id.bylex.su/info/terms">https://id.bylex.su/info/terms</a>, а также иных заключаемых с Пользователем договоров, когда это прямо предусмотрено их условиями. Таким образом, заключая Соглашение и указанные договоры установленным в них способом, включая использование Приложения, вы принимаете условия настоящей Политики в полном объеме.</li>
									<li id="2.12">Способы обработки Персональной информации включают любые действия (операции) или комбинацию действий (операций), в том числе, сбор, запись, систематизация, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление, уничтожение в установленных настоящей Политикой целях с использованием средств автоматизации или без использования таких средств по усмотрению byLex'а.</li>
									<li id="2.13">Здесь и далее в Политике используются термины и определения, предусмотренные Соглашением, а также иными заключаемыми с Пользователем договорами, если иное не предусмотрено настоящей Политикой или не вытекает из ее существа. В иных случаях толкование применяемого в Политике термина производится в соответствии с применимым законодательством, обычаями делового оборота, или научной доктриной.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="2">Персональная информация</h5>
								<ol>
									<li>
										<p id="2.1">Под Персональной информацией в настоящей Политике понимается:</p>
										<ol>
											<li id="2.1.1">Информация, которую Пользователь предоставляет о себе самостоятельно при регистрации или авторизации, а также в процессе дальнейшего использования Приложения и/или Сервиса, включая персональные данные Пользователя.</li>
											<li id="2.1.2">Данные, которые передаются в автоматическом режиме в зависимости от настроек программного обеспечения Пользователя в обезличенном виде.</li>
										</ol>
									</li>
									<li id="2.2">byLex вправе устанавливать требования к составу Персональной информации Пользователя, которая должна обязательно предоставляться для использования Приложения и основанного на нем Сервиса. Если определенная информация не помечена byLex'ом как обязательная, ее предоставление или раскрытие осуществляется Пользователем по своему усмотрению.</li>
									<li id="2.3">При регистрации Пользователем обязательно указываются имя учётной записи (логин), адрес электронной почты и пароль.<br>
									При регистрации byLex создает уникальный идентификатор каждого Пользователя (user_id). Идентификатор Пользователя привязан к информации профиля Пользователя.<br>
									В целях заключения Договоров Пользователь также может предоставить следующие персональные данные о себе: имя, должность, телефонный номер.<br>
									Если физическое лицо действует в интересах организации, дополнительно могут предоставляться наименование, ИИН, КПП, ОГРН, адрес регистрации и почтовый адрес такой организации, корпоративный телефонный номер, расчетный счет, наименование обслуживающего банка, его БИК и корреспондентский счет.
									Указанные сведения об организации не относятся к персональным данным.<br>
									Если физическое лицо действует в качестве индивидуального предпринимателя или в его интересах, по желанию или с непосредственного письменного согласия такого индивидуального предпринимателя, соответственно, дополнительно могут предоставляться: фамилия и инициалы индивидуального предпринимателя, ИИН, ОГРНИП, и адрес для переписки, телефонный номер, расчетный счет, наименование обслуживающего банка, его БИК и корреспондентский счет.</li>
									<li id="2.4">Данные, раскрываемые Пользователем неопределенному кругу лиц. С использованием функциональных возможностей Приложения Пользователь может самостоятельно, своими действиями, по своему усмотрению, раскрывать неопределенному кругу лиц (публиковать в Приложении) информацию своего профиля в Приложении, которая может включать персональные данные, в том числе: фамилия, имя, отчество, пол, дата рождения, город местонахождения, место работы, должность, сведения о себе в свободной форме. Добавленная Пользователем в профиль информациюя становится доступной неограниченному кругу лиц, с учетом настроек отображения информации профиля в Сервисе.
									</li>
									<li id="2.5">byLex не осуществляет проверку достоверности предоставляемой Персональной информации и наличия у Пользователя необходимого согласия на ее обработку в соответствии с настоящей Политикой, полагая, что Пользователь действует добросовестно, осмотрительно и прилагает все необходимые усилия к поддержанию такой информации в актуальном состоянии и получению всех необходимых согласий субъектов персональных данных.</li>
									<li id="2.6">byLex осуществляет сбор и хранение данных о действиях Пользователя в Сервисе, используя при этом файлы журналов. В случае, если действия совершены авторизованным Пользователем, такая информация связывается с идентификатором Пользователя.</li>
									<li id="2.7">
										<p>Пользователь осознает и принимает возможность использования на Сайте и в Мобильном приложении программного обеспечения третьих лиц, в результате чего такие лица могут получать и передавать указанные в п.2.1.2 данные в обезличенном виде.</p>
										<p>К указанному программному обеспечению третьих лиц относятся:</p>
										<ul>
											<li>системы по сбору аналитических данных: Google Analytics, Яндекс.Метрика, Yandex AppMetrica, Fabric, Firebase, Медиатор.</li>
											<li>Инструменты аналитики (пиксели) социальных сетей: Facebook, ВКонтакте;</li>
											<li>системы размещения рекламы: Google DoubleClick for Publishers (DFP), Google Adsense, Рекламная сеть Яндекса (РСЯ), Criteo.
										</li></ul>
										<p>Состав и условия сбора обезличенных данных с использованием программного обеспечения третьих лиц определяются непосредственно их правообладателями и могут включать:</p>
										<ul>
											<li>данные браузера (тип, версия, cookie);</li>
											<li>данные операционной системы (тип, версия, разрешение экрана);</li>
											<li>данные запроса (время, источник перехода, IP-адрес).</li>
											<li>иные обезличенные данные о действиях Пользователя в Приложении.</li>
										</ul>
									</li>
									<li id="2.8">В рамках оказания отдельных видов услуг byLex может предоставлять Пользователям возможность самостоятельно размещать на отдельных страницах Сайта программное обеспечение для сбора и обработки обезличенных данных, аналогичное указанному в п. 2.7. настоящей Политики, в результате чего такие Пользователи могут получать указанные в п.2.1.2 данные в обезличенном виде. Состав и условия сбора обезличенных данных в таком случае определяется Пользователем, разместившим программное обеспечение для сбора и обработки обезличенных данных, но не может вступать в противоречие с настоящей Политикой. </li>
									<li id="2.9">byLex не несет ответственность за порядок использования Персональной информации Пользователя третьими лицами, с которыми Пользователь взаимодействует в рамках использования Приложения и/или Сервиса.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="3">Правовые основания обработки Персональной информации</h5>
								<ol>
									<li>
										<p id="3.1">Правовые основания, на которых byLex использует Персональную информацию, включают:</p>
										<ol>
											<li id="3.1.1">Обработку Персональной информации, когда Пользователь предоставил согласие на обработку для одной или нескольких конкретных целей;</li>
											<li id="3.1.2">Обработку Персональной информации, когда это необходимо для исполнения договора, стороной которого является Пользователь;</li>
											<li id="3.1.3">Обработку Персональной информации, когда это необходимо для заключения договора с Пользователем; </li>
											<li id="3.1.4">Обработку Персональной информации, которая необходима для исполнения byLex'ом своих обязательств в соответствии с применимым правом.</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="4">Политика применительно к детям</h5>
								<ol>
									<li id="4.1">byLex не осуществляет намеренный сбор персональных данных у детей в возрасте до 18 лет, не запрашивает ее и не разрешает им пользоваться Приложением. Лицам, не достигшим 18 лет, не разрешается предоставлять личную информацию, включая имя, адрес, номер телефона и адрес электронной почты. Если byLex'у станет известно о том, что информация была получена от ребенка в возрасте до 18 лет, byLex безотлагательно удалит такую информацию. Если вы считаете, что нами могла быть получена какая-либо информация от ребенка или о ребенке в возрасте младше 18 лет, пожалуйста, свяжитесь с нами по электронной почте: lexin.ucoz.ru@gmail.com.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="5">Цели обработки Персональной информации</h5>
								<ol>
									<li id="5.1">byLex осуществляет обработку, в том числе сбор и хранение той Персональной информации, которая необходима для заключения и исполнения договоров с Пользователем, а также сделана общедоступной Пользователем путем заполнения профиля и установки настроек в Личном кабинете.</li>
									<li id="5.2">
										<p>byLex вправе использовать Персональную информацию в следующих целях:</p>
										<ol>
											<li id="5.2.1">Заключение договоров на использование Приложения и Сервиса;
											Заключение Договора осуществляется в дистанционном порядке посредством обмена электронными документами, которые подписываются простой электронной подписью. При этом роль ключа простой электронной подписи Пользователя могут выполнять пара логин — пароль или адрес его электронной почты. Поэтому они запрашиваются при регистрации.</li>
											<li id="5.2.2">Исполнение обязательств по заключенным договорам, включая предоставление Пользователю доступа к Приложению и Сервису;
											Заявки на предоставление доступа к Сервису принимаются с использованием программных средств Приложения и электронной почты. В рамках реализации функциональных возможностей Сервиса Правообладатель хранит, систематизирует и отображает в Приложении профили Пользователей, содержащие Персональную информацию.</li>
											<li id="5.2.3">Идентификация Пользователя в рамках исполнения обязательств по заключенным с ним договорам;
											Личный кабинет Пользователя связан с логином-паролем и адресом электронной почты Пользователя.</li>
											<li id="5.2.5">Оказание технической поддержки в связи с использованием Приложения и Сервиса;
											Обращения в службу поддержки принимаются с использованием программных средств Приложения и электронной почты.</li>
											<li id="5.2.6">Обеспечение связи с Пользователем в целях информационного обслуживания и улучшения качества Сервиса по заключенным Договорам, в том числе в порядке нотификации с привлечением третьих лиц;
											Связь с Пользователем осуществляется через электронную почту или по абонентскому номеру телефона, указанным Пользователем при регистрации или в процессе использования Приложения и/или Сервиса.</li>
											<li id="5.2.7">Использование обезличенных данных для таргетинга рекламных и/или информационных материалов по возрасту, полу, другим признакам.</li>
											<li id="5.2.8">Проведение маркетинговых, статистических и иных исследований на основе обезличенных данных.
											Данные из указанных в п. 2.7. систем сбора данных в обезличенном виде собираются и анализируются, чтобы узнать, сколько времени Пользователи проводят в различных разделах Приложения, их интересах и предпочтениях.</li>
											<li id="5.2.9">Исполнение требования законодательства.<br/>Являясь организатором распространения информации в сети «Интернет», byLex хранит информацию о фактах приема, передачи, доставки и (или) обработки письменного текста, изображений, звуков, видео- или иных электронных сообщений пользователей сети «Интернет» и информацию об этих пользователях в течение одного года с момента окончания осуществления таких действий.</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="6">Права Пользователя в отношении Персональной информации</h5>
								<ol>
									<li>
										<p id="6.1">Если это предусмотрено применимым законодательством, Пользователь имеет право:</p>
										<ol>
											<li id="6.1.1">Получать информацию о сборе и использовании его персональных данных.</li>
											<li id="6.1.2">Получить доступ к своим персональным данным и исправлять их, если они неверные или неполные.</li>
											<li id="6.1.3">Запретить обработку своих персональных данных в случае, если их точность оспаривается, обработка осуществляется неправомерно, а также в случаях, когда byLex'у более не требуются персональные данные для целей, в которых они обрабатывались byLex'ом.</li>
											<li id="6.1.4">Возражать против обработки своих персональных данных, а также запрещать их обработку в случаях, если byLex обрабатывал их при исполнении задач в общественно-полезных или в собственных законных интересах, и при этом отсутствует вынужденная необходимость в продолжении такой обработки.</li>
											<li id="6.1.5">В любое время отозвать согласие, которое Пользователь предоставил на обработку своих персональных данных. В случае отзыва Пользователем своего согласия на обработку персональных данных, такой отзыв не повлияет на правомерность обработки, которую byLex осуществлял на основании такого согласия до его отзыва. </li>
											<li id="6.1.5">Требовать удаления своих персональных данных, в случаях: когда они более не относятся к целям, для которых они были собраны или обрабатывались; когда отозвано согласие и у byLex'а отсутствуют основания для продолжения обработки; когда имеются возражения против дальнейшей обработки в общественно-полезных или в собственных законных интересах byLex'а и отсутствует вынужденная необходимость в продолжении такой обработки; когда персональные данные обрабатывались неправомерно.</li>
											<li id="6.1.7">Не быть субъектом решения, основанного исключительно на автоматизированной обработке, включая профилирование, которое создает для Пользователя юридически значимые последствия или затрагивает иным аналогичным образом, за исключением случаев, когда существует правовое основание для продолжения обработки.</li>
											<li id="6.1.8">Получать персональные данные в структурированной, широко используемой, и машиночитаемой форме, чтобы иметь возможность переносить данные, в случаях, когда byLex обрабатывает предоставленные персональные данные автоматизированными средствами.</li>
										</ol>
									</li>
									<li id="6.2">Указанные права могут быть реализованы путем использования инструментов, которые byLex предлагает в Приложении или посредством направления специального запроса byLex'у способами, описанными ниже. byLex своевременно ответит на любые такие запросы в соответствии с применимым правом. В некоторых случаях byLex может попросить Пользователя пройти процедуру идентификации перед обработкой запроса. Пользователь вправе обратиться к соответствующему надзорному органу в своей юрисдикции, если ответ byLex'а его не удовлетворяет.
									</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="7">Хранение Персональной информации</h5>
								<ol>
									<li id="7.1">Вся собранная Персональная информация хранится только в течение периода, когда она необходима для достижения целей, в которых такая информация была собрана, или в течение периода, разрешенного или требуемого в соответствии с положениями применимого законодательства. Периодически byLex проводит проверку систем обработки данных, для определения того, остаются ли действительными цели сбора и обработки персональных данных пользователей. Такое определение основано на ряде факторов, который включает, но не ограничивается следующим: поддерживает ли Пользователь отношения с byLex'ом, выполнены ли byLex'ом запросы Пользователя (включая любые дополнительные задания), существуют ли договорные отношения между Пользователем и byLex'ом, а также существуют ли договорные или законные основания для продолжения хранения персональных данных Пользователя. Основываясь на результатах такой проверки и уведомлениях, полученных от субъектов персональных данных, byLex обновляет свои системы.</li>
									<li id="7.2">В целях повышения качества Сервиса и обеспечения возможности правовой защиты, byLex вправе хранить лог-файлы о действиях, совершенных Пользователем в рамках использования Приложения и/или Сервиса, а также в связи с заключением и исполнением Пользователем Соглашения и иных договоров со своей стороны, в течение 1 (Одного) года с момента их совершения.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="8">Требования к защите Персональной информации</h5>
								<ol>
									<li id="8.1">byLex осуществляет хранение Персональной информации и обеспечивает ее охрану от несанкционированного доступа и распространения в соответствии с внутренними правилами и регламентами.</li>
									<li id="8.2">В отношении Персональной информации Пользователя сохраняется ее конфиденциальность, за исключением информации, раскрытой Пользователем неограниченному кругу лиц (п. 2.4. настоящей Политики), и иных случаев, когда технология Приложения или предоставляемого на его основе Сервиса либо настройки используемого Пользователем программного обеспечения предусматривают открытый обмен информацией с иными Пользователями Приложения или с любыми пользователями сети Интернет.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="9">Передача Персональной информации</h5>
								<ol>
									<li>
										<p id="9.1">byLex вправе передавать Персональную информацию третьим лицам в следующих случаях:</p>
										<ol>
											<li id="9.1.1">Пользователь выразил свое согласие на такие действия, включая случаи применения Пользователем настроек используемого программного обеспечения, не ограничивающих предоставление определенной информации;</li>
											<li id="9.1.2">Передача необходима в рамках использования Пользователем функциональных возможностей Приложения и/или специфики основанного на нем Сервиса;</li>
											<li id="9.1.3">В связи с передачей Приложения во владение, пользование или собственность такого третьего лица, включая уступку прав по заключенным с Пользователем договорам в пользу такого третьего лица;</li>
											<li id="9.1.4">По запросу суда или иного уполномоченного государственного органа в рамках установленной законодательством процедуры;</li>
											<li id="9.1.5">Для защиты прав и законных интересов byLex'а в связи с нарушением заключенных с Пользователем договоров.</li>
										</ol>
									</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="10">Изменение и удаление Персональной информации</h5>
								<ol>
									<li id="10.1">Пользователь вправе в любой момент самостоятельно отредактировать в своем Личном кабинете предоставленную им при регистрации или авторизации Персональную информацию.</li>
									<li id="10.2">Пользователь вправе удалить собственную учетную запись либо реализовать право на отзыв согласия на обработку его персональных данных путем направления byLex'у запроса на удаление своей учетной записи по адресу <a href="mailto:lexin.ucoz.ru@gmail.com">lexin.ucoz.ru@gmail.com</a>.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="11">Изменение Политики конфиденциальности</h5>
								<ol>
									<li id="11.1">Настоящая Политика может быть изменена или прекращена byLex'ом в одностороннем порядке без предварительного уведомления Пользователя. Новая редакция Политики вступает в силу с момента ее размещения на Сайте byLex'а, если иное не предусмотрено новой редакцией Политики.</li>
									<li id="11.2">Действующая редакция Политики находится на Сайте byLex'а в сети Интернет по адресу <a href="https://id.bylex.su/info/privacy">https://id.bylex.su/info/privacy</a>.</li>
								</ol>
							</li>
						</ol>
						<p>
							ИП «byLex»<br/>
							Регистрационный номер: 1234567890<br/>
							Адрес: 010013, Казахстан, Астана, ул. Неизвестная, д. 3<br/>
							Любые вопросы по настоящей Политике могут быть направлены по адресу: <a href="mailto:lexin.ucoz.ru@gmail.com">lexin.ucoz.ru@gmail.com</a>
						</p>
					</div>
					<!--<p><router-link :to="{path: '/info/privacy_20200207'}">Редакция Политики конфиденциальности от 7 февраля 2020.</router-link></p>-->
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
ol {
    counter-reset: list1;
    list-style: none;
    padding: 0 0 0 20px;
}
ol ol {
    counter-reset: list2;
    padding: 0 0 0 34px;
}
ol ol ol {
    counter-reset: list3;
    padding: 0 0 0 34px;
}

h5,
li {
    position: relative;
}
ol h5,
ol li {
    position: relative;
}
ol ol h5,
ol ol li {
    position: relative;
}
ol ol ol h5,
ol ol ol li {
    position: relative;
}

ol li {
    position: relative;
    margin: 0 0 36px;
}
ol li::before {
    content: counter(list1) ". ";
    counter-increment: list1;
    font-weight: 600;
    position: absolute;
    right: 100%;
    padding: 0 6px 0 0;
}

ol ol li {
    margin: 0 0 16px;
}
ol ol li::before {
    counter-increment: list2;
    content: counter(list1) "." counter(list2) ". ";
}

ol ol ol li {
    margin: 20px 0;
}
ol ol ol li::before {
    counter-increment: list3;
    content: counter(list1) "." counter(list2) "." counter(list3) ". ";
    padding: 0 8px 0 0;
    font-weight: normal;
}

p:first-child {
    margin-top: 0;
}
p {
    margin-top: 16px;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Политика конфиденциальности | byLex Account'});
	},
	data: () => ({
		
	}),
	methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		//Navbar,
	},
};
</script>