<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-12 col-lg-10 col-xl-8">
			<div class="row mb-5">
				<div class="col-md-10">
					<h6>{{$t('text-settings-hello')}} @{{(authModel.userProfile||{}).login||''}}</h6>
					<p>{{$t('text-settings-description')}}</p>
				</div>
				<div class="col-md-2 d-none d-md-block">
					<svg width="100" height="100">
						<use xlink:href="/assets/images/icons-sprite.svg#welcome"></use>
					</svg>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush7">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading7">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
							<svg class="svg-icon svg-icon_rounded svg-icon_yellow" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#questionary"></use>
							</svg>
							{{$t('header-settings-change-questionary')}}
						</button>
					</h2>
					<div id="flush-collapse7" class="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#accordionFlush7">
						<div class="accordion-body">
							<form @submit.prevent="onChangeQuestionary">
								<div class="row mb-3">
									<div class="col-4 col-lg-3">
										<img :src="changeQuestionaryAvaPreview" class="img-thumbnail w-100" />
									</div>
									<div class="col-8 col-lg-9">
										<label for="inpAva" class="form-label">{{$t('label-settings-change-ava')}}</label>
										<input type="file" ref="avatar" class="form-control" id="inpAva" autocomplete="off" @change="handleQuestionaryAvaFileUpload()" accept="image/png, image/gif, image/jpeg" />
									</div>
								</div>
								<div class="mb-3">
									<label for="inpSname" class="form-label">{{$t('label-settings-change-sname')}}</label>
									<input type="text" class="form-control" id="inpSname" autocomplete="off" v-model="changeQuestionarySname" />
								</div>
								<div class="mb-3">
									<label for="inpFname" class="form-label">{{$t('label-settings-change-fname')}}</label>
									<input type="text" class="form-control" id="inpFname" autocomplete="off" v-model="changeQuestionaryFname" />
								</div>
								<div class="mb-3">
									<label for="inpLname" class="form-label">{{$t('label-settings-change-lname')}}</label>
									<input type="text" class="form-control" id="inpLname" autocomplete="off" v-model="changeQuestionaryLname" />
								</div>
								<div class="mb-3">
									<label class="form-label">{{$t('label-settings-change-gender')}}</label>
									<div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="radGenderMale" value="1" v-model="changeQuestionaryGender">
											<label class="form-check-label" for="radGenderMale">{{$t('label-settings-change-gender-male')}}</label>
										</div>
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="radio" id="radGenderFemale" value="0" v-model="changeQuestionaryGender">
											<label class="form-check-label" for="radGenderFemale">{{$t('label-settings-change-gender-female')}}</label>
										</div>
									</div>
								</div>
								<div class="mb-3">
									<label for="inpDateofbirth" class="form-label">{{$t('label-settings-change-dateofbirth')}}</label>
									<input type="date" class="form-control" id="inpDateofbirth" autocomplete="off" v-model="changeQuestionaryDateofbirth" />
								</div>
								<button type="submit" class="btn btn-primary" :disabled="btnChangeQuestionaryDisabled">{{$t('btn-save')}}</button>
								<div class="alert alert-warning alert-dismissible mt-3 mb-0" v-if="changeQuestionaryError">
									{{changeQuestionaryError}}
									<button type="button" class="btn-close" @click="changeQuestionaryError = ''"></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush5">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading5">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse5" aria-expanded="false" aria-controls="flush-collapse5">
							<svg class="svg-icon svg-icon_rounded svg-icon_blue" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#user"></use>
							</svg>
							{{$t('header-settings-change-login')}}
						</button>
					</h2>
					<div id="flush-collapse5" class="accordion-collapse collapse" aria-labelledby="flush-heading5" data-bs-parent="#accordionFlush5">
						<div class="accordion-body">
							<form @submit.prevent="onChangeLogin">
								<div class="mb-3">
									<label for="inpLogin1" class="form-label">{{$t('label-settings-change-login-current-login')}}</label>
									<input type="text" class="form-control" id="inpLogin1" :value="(authModel.userProfile||{}).login||''" disabled />
								</div>
								<div class="mb-3">
									<label for="inpLogin2" class="form-label">{{$t('label-settings-change-login-new-login')}}</label>
									<input type="text" class="form-control" id="inpLogin2" autocomplete="off" v-model="changeLoginLogin" required />
								</div>
								<button type="submit" class="btn btn-primary" :disabled="btnChangeLoginDisabled">{{$t('btn-save')}}</button>
								<div class="alert alert-warning alert-dismissible mt-3 mb-0" v-if="changeLoginError">
									{{changeLoginError}}
									<button type="button" class="btn-close" @click="changeLoginError = ''"></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush1">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading1">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse1" aria-expanded="false" aria-controls="flush-collapse1">
							<svg class="svg-icon svg-icon_rounded svg-icon_blue" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#at"></use>
							</svg>
							{{$t('header-settings-change-email')}}
						</button>
					</h2>
					<div id="flush-collapse1" class="accordion-collapse collapse" aria-labelledby="flush-heading1" data-bs-parent="#accordionFlush1">
						<div class="accordion-body">
							<form @submit.prevent="onChangeEmail">
								<div class="mb-3">
									<label for="inpEmail1" class="form-label">{{$t('label-settings-change-email-current-email')}}</label>
									<input type="email" class="form-control" id="inpEmail1" :value="(authModel.userProfile||{}).email||''" disabled />
								</div>
								<div class="mb-3">
									<label for="inpEmail2" class="form-label">{{$t('label-settings-change-email-new-email')}}</label>
									<input type="email" class="form-control" id="inpEmail2" autocomplete="off" v-model="changeEmailEmail" required />
								</div>
								<button type="submit" class="btn btn-primary" :disabled="btnChangeEmailDisabled">{{$t('btn-save')}}</button>
								<div class="alert alert-warning alert-dismissible mt-3 mb-0" v-if="changeEmailError">
									{{changeEmailError}}
									<button type="button" class="btn-close" @click="changeEmailError = ''"></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush2">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading2">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2" aria-expanded="false" aria-controls="flush-collapse2">
							<svg class="svg-icon svg-icon_rounded svg-icon_cian" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#lock"></use>
							</svg>
							{{$t('header-settings-change-password')}}
						</button>
					</h2>
					<div id="flush-collapse2" class="accordion-collapse collapse" aria-labelledby="flush-heading2" data-bs-parent="#accordionFlush2">
						<div class="accordion-body">
							<form @submit.prevent="onChangePassword">
								<div class="mb-3">
									<label for="inpPassword1" class="form-label">{{$t('label-settings-change-password-new-password')}}</label>
									<input type="password" class="form-control" id="inpPassword1" autocomplete="off" v-model="changePasswordPassword1" required />
								</div>
								<div class="mb-3">
									<label for="inpPassword2" class="form-label">{{$t('label-settings-change-password-reenter-new-password')}}</label>
									<input type="password" class="form-control" id="inpPassword2" autocomplete="off" v-model="changePasswordPassword2" required />
								</div>
								<button type="submit" class="btn btn-primary" :disabled="btnChangePasswordDisabled">{{$t('btn-save')}}</button>
								<div class="alert alert-warning alert-dismissible mt-3 mb-0" v-if="changePasswordError">
									{{changePasswordError}}
									<button type="button" class="btn-close" @click="changePasswordError = ''"></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush6">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading6">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
							<svg class="svg-icon svg-icon_rounded svg-icon_2fa" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#2fa"></use>
							</svg>
							{{$t('header-settings-2fa')}}
						</button>
					</h2>
					<div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#accordionFlush6">
						<div class="accordion-body">
							<form @submit.prevent="onEnable2fa" v-if="(this.authModel.userProfile||{}).google2fa_secret == null">
								<div class="row">
									<div class="col-4">
										<img :src="enable2faQr" class="img-thumbnail" />
									</div>
									<div class="col-8">
										<div class="mb-3">
											<p><b>{{$t('text-settings-2fa-step1')}}.</b> {{$t('text-settings-2fa-step1-text')}}</p>
											<p><b>{{$t('text-settings-2fa-step2')}}.</b> {{$t('text-settings-2fa-step2-text')}}</p>
											<div class="input-group mb-3">
												<input type="text" class="form-control font-monospace no-controls" readonly :value="splitrazr(enable2faSecret, 4)" />
											</div>
											<p><b>{{$t('text-settings-2fa-step3')}}.</b> {{$t('text-settings-2fa-step3-text')}}</p>
										</div>
										<div class="mb-3">
											<label for="inpEnable2faCode" class="form-label">{{$t('label-settings-2fa-code')}}</label>
											<div class="input-group">
												<input type="number" class="form-control no-controls" id="inpEnable2faCode" autocomplete="off" :placeholder="$t('placeholder-settings-2fa-code')" v-model="enable2faCode" onwheel="return false" required />
												<button type="submit" class="btn btn-primary" :disabled="btnEnable2faDisabled">{{$t('btn-enable')}}</button>
											</div>
										</div>
									</div>
								</div>
								<div class="alert alert-warning alert-dismissible mt-3 mb-0" v-if="enable2faError">
									{{enable2faError}}
									<button type="button" class="btn-close" @click="enable2faError = ''"></button>
								</div>
							</form>
							<form @submit.prevent="onDisable2fa" v-else>
								<button type="submit" class="btn btn-primary" :disabled="btnDisable2faDisabled">{{$t('btn-disable')}}</button>
								<div class="alert alert-warning alert-dismissible mt-3 mb-0" v-if="disable2faError">
									{{disable2faError}}
									<button type="button" class="btn-close" @click="disable2faError = ''"></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush3">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading3">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3" aria-expanded="false" aria-controls="flush-collapse3">
							<svg class="svg-icon svg-icon_rounded svg-icon_green" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#key"></use>
							</svg>
							{{$t('header-settings-keychain')}}
						</button>
					</h2>
					<div id="flush-collapse3" class="accordion-collapse collapse" aria-labelledby="flush-heading3" data-bs-parent="#accordionFlush3">
						<div class="accordion-body">
							<p>{{$t('text-settings-keychain')}}</p>
							<div class="card shadow-none" v-for="provider in appModel.providers" :key="provider.type">
								<div class="card-body d-flex align-items-center">
									<div class="me-auto">
										<div class="d-inline-block bg-secondary rounded me-2" :class="['icon-'+provider.icon]">
											<svg width="36" height="36">
												<use :xlink:href="'/assets/images/icons-sprite.svg#'+provider.icon"></use>
											</svg>
										</div>
										<b>{{provider.name}}</b>
									</div>
									<div class="btn btn-outline-danger" @click="onDeleteKeychain(provider.type, getProviderKeychain(provider.type).id)" v-if="!!getProviderKeychain(provider.type)">{{$t('btn-disconnect')}}</div>
									<div class="btn btn-outline-primary" @click="onAddKeychain(provider.type, providerTypes[provider.type])" v-else>{{$t('btn-connect')}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush8">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading8">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
							<svg class="svg-icon svg-icon_rounded svg-icon_gray" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#auth-history"></use>
							</svg>
							{{$t('header-settings-auth-history')}}
						</button>
					</h2>
					<div id="flush-collapse8" class="accordion-collapse collapse" aria-labelledby="flush-heading8" data-bs-parent="#accordionFlush8">
						<div class="accordion-body">
							<table class="table" :class="{'mb-0': authHistoryBtnHide}">
								<thead>
									<tr>
										<th width="110" style="text-align: center;">Устройство</th>
										<th style="text-align: center;">ОС и браузер</th>
										<th width="130" style="text-align: center;">Дата и время</th>
										<th width="130" style="text-align: center;">IP</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="hist in authHistory" :key="hist.id">
										<td style="vertical-align: middle; text-align: center;">
											<img :src="'/assets/images/devices/'+(hist.device_type||'unknown')+'.png'" style="width: 40px; height: 40px;" :title="(hist.device_type||'unknown')" class="d-inline me-1" />
											<img :src="'/assets/images/platforms/'+getOsImg(hist.os)+'.png'" style="width: 40px; height: 40px;" :title="hist.os" class="d-inline" />
										</td>
										<td style="vertical-align: middle; text-align: center;">
											{{hist.os}}, {{hist.browser}} v.{{hist.browser_version}}
										</td>
										<td style="vertical-align: middle; text-align: center;">
											{{formatDate(hist.ugmtime_add, 'DD.MM.YYYY hh:mm:ss')}}
										</td>
										<td style="vertical-align: middle; text-align: center;">
											{{hist.ip}}
										</td>
									</tr>
								</tbody>
							</table>
							<button type="button" @click="getAuthHistory()" class="btn btn-secondary d-block mx-auto" v-if="!authHistoryBtnHide">{{$t('btn-show-more')}}</button>
						</div>
					</div>
				</div>
			</div>
			<div class="accordion accordion-flush shadow-sm mb-3" id="accordionFlush4">
				<div class="accordion-item">
					<h2 class="accordion-header" id="flush-heading4">
						<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse4" aria-expanded="false" aria-controls="flush-collapse4">
							<svg class="svg-icon svg-icon_rounded svg-icon_brown" width="32" height="32">
								<use xlink:href="/assets/images/icons-sprite.svg#flag"></use>
							</svg>
							{{$t('header-settings-delete-account')}}
						</button>
					</h2>
					<div id="flush-collapse4" class="accordion-collapse collapse" aria-labelledby="flush-heading4" data-bs-parent="#accordionFlush4">
						<div class="accordion-body">
							<form @submit.prevent="onDeleteAccount">
								<div class="mb-3">
									<label for="inpAccountPassword" class="form-label">{{$t('label-settings-delete-account-password')}}</label>
									<input type="password" class="form-control" id="inpAccountPassword" autocomplete="off" v-model="deleteAccountCurrentPassword" required />
								</div>
								<button type="submit" class="btn btn-danger" :disabled="btnDeleteAccountDisabled">{{$t('btn-delete-account')}}</button>
								<div class="alert alert-warning alert-dismissible mt-3 mb-0" v-if="deleteAccountError">
									{{deleteAccountError}}
									<button type="button" class="btn-close" @click="deleteAccountError = ''"></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade" id="cropperModal" tabindex="-1" aria-labelledby="cropperModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="cropperModalLabel">Установка аватарки</h1>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					<div class="cropper">
						<cropper
							:src="img"
							ref="cropper"
							:stencil-size="{
								width: 330,
								height: 330,
							}"
							:stencil-props="{
								handlers: {},
								movable: false,
								resizable: false,
								aspectRatio: 1,
							}"
							image-restriction="stencil"
						/>
						<div class="cropper-vertical-buttons">
							<div class="cropper-square-button" @click="onCropperFlip(true, false)" title="Flip Horizontal">
								<img src="/assets/images/cropper/icons/flip-horizontal.svg" />
							</div>
							<div class="cropper-square-button" @click="onCropperFlip(false, true)" title="Flip Vertical">
								<img src="/assets/images/cropper/icons/flip-vertical.svg" />
							</div>
							<div class="cropper-square-button" @click="onCropperRotate(90)" title="Rotate Clockwise">
								<img src="/assets/images/cropper/icons/rotate-clockwise.svg" />
							</div>
							<div class="cropper-square-button" @click="onCropperRotate(-90)" title="Rotate Counter-Clockwise">
								<img src="/assets/images/cropper/icons/rotate-counter-clockwise.svg" />
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-primary" @click="onCropperCrop()" data-bs-dismiss="modal">Применить</button>
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
.cropper {
	height: 450px;
	width: 100%;
	background: #ddd;
	position: relative;
}
.cropper-vertical-buttons {
	position: absolute;
	left: 50%;
	top: 10px;
	transform: translateX(-50%);
	display: flex;
	&::selection {
		background: transparent;
	}
	&::-moz-selection {
		background: transparent;
	}
}
.cropper-square-button {
	background: rgba(black, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 40px;
	margin: 0 5px;
	cursor: pointer;
	transition: background 0.5s;
	&:hover {
		background: #000;
	}
}

.svg-icon {
    padding: 0;
    font-size: 0;
    flex: none;
	&_rounded {
		width: 32px;
		height: 32px;
		background-color: #d3dbdd;
		border-radius: 3px;
		margin-right: 16px;
	}
	&_yellow {
		background-color: #fb9700;
	}
	&_blue {
		background-color: #558cb7;
	}
	&_cian {
		background-color: #55b7ad;
	}
	&_green {
		background-color: #69b755;
	}
	&_brown {
		background-color: #b75555;
	}
	&_gray {
		background-color: #999;
	}
	&_2fa {
		background-color: #0a4b52;
	}
}

input[type=number].no-controls::-webkit-outer-spin-button,
input[type=number].no-controls::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';
//import { useI18n } from 'vue-i18n';
import moment from 'moment';

import { Facebook, Google, Github, Twitter, Vkontakte, Live } from 'universal-social-auth';
import { Discord, Mailru, Twitch, Yandex } from '@/assets/plugins/socials';

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import { Modal } from 'bootstrap';

export default {
	mixins: lib.mixins,
	setup(){
		//const { t } = useI18n();
		//useMeta({title: 'Настройки аккаунта | byLex Account'+t('logo-text')});
		useMeta({title: 'Настройки аккаунта | byLex Account'});
	},
	data: () => ({
		changeQuestionaryAvaPreview: '',
		changeQuestionaryAva: null,
		changeQuestionarySname: '',
		changeQuestionaryFname: '',
		changeQuestionaryLname: '',
		changeQuestionaryGender: null,
		changeQuestionaryDateofbirth: null,
		btnChangeQuestionaryDisabled: false,
		changeQuestionaryError: '',
		
		changeLoginLogin: '',
		btnChangeLoginDisabled: false,
		changeLoginError: '',
		
		changeEmailEmail: '',
		btnChangeEmailDisabled: false,
		changeEmailError: '',
		
		changePasswordPassword1: '',
		changePasswordPassword2: '',
		btnChangePasswordDisabled: false,
		changePasswordError: '',
		
		enable2faQr: '',
		enable2faSecret: '',
		enable2faCode: '',
		btnEnable2faDisabled: false,
		enable2faError: '',
		
		btnDisable2faDisabled: false,
		disable2faError: '',
		
		authHistory: [],
		authHistoryStart: 0,
		authHistoryCount: 0,
		authHistoryBtnHide: false,
		
		deleteAccountCurrentPassword: '',
		btnDeleteAccountDisabled: false,
		deleteAccountError: '',
		
		providerTypes: {
			facebook: Facebook,
			google: Google,
			github: Github,
			twitter: Twitter,
			vkontakte: Vkontakte,
			microsoft: Live,
			discord: Discord,
			mailru: Mailru,
			twitch: Twitch,
			yandex: Yandex,
		},
		
		img: null,
	}),
	methods: {
		async onChangeQuestionary(){
			this.btnChangeQuestionaryDisabled = true;
			this.changeQuestionaryError = '';
			
			let res = await this.authModel.doChangeQuestionary(
				this.changeQuestionaryAvaPreview,
				this.changeQuestionarySname,
				this.changeQuestionaryFname,
				this.changeQuestionaryLname,
				this.changeQuestionaryGender,
				this.changeQuestionaryDateofbirth
			);
			
			this.btnChangeQuestionaryDisabled = false;
			if(res.success){
				this.authModel.userProfile.sname = this.changeQuestionarySname;
				this.authModel.userProfile.fname = this.changeQuestionaryFname;
				this.authModel.userProfile.lname = this.changeQuestionaryLname;
				this.authModel.userProfile.gender = this.changeQuestionaryGender;
				this.authModel.userProfile.dateofbirth = this.changeQuestionaryDateofbirth;
				this.changeQuestionaryAvaPreview = this.authModel.userProfile.ava;
			} else {
				this.changeQuestionaryError = res.error;
			}
		},
		
		async onChangeLogin(){
			this.btnChangeLoginDisabled = true;
			this.changeLoginError = '';
			
			let res = await this.authModel.doChangeLogin(this.changeLoginLogin);
			
			this.btnChangeLoginDisabled = false;
			if(res.success){
				this.authModel.userProfile.login = this.changeLoginLogin;
				this.changeLoginLogin = '';
			} else {
				this.changeLoginError = res.error;
			}
		},
		
		async onChangeEmail(){
			this.btnChangeEmailDisabled = true;
			this.changeEmailError = '';
			
			let res = await this.authModel.doChangeEmail(this.changeEmailEmail);
			
			this.btnChangeEmailDisabled = false;
			if(res.success){
				this.authModel.userProfile.email = this.changeEmailEmail;
				this.changeEmailEmail = '';
			} else {
				this.changeEmailError = res.error;
			}
		},
		
		async onChangePassword(){
			this.btnChangePasswordDisabled = true;
			this.changePasswordError = '';
			
			let res = await this.authModel.doChangePassword(this.changePasswordPassword1, this.changePasswordPassword2);
			
			this.btnChangePasswordDisabled = false;
			if(res.success){
				this.changePasswordPassword1 = '';
				this.changePasswordPassword2 = '';
			} else {
				this.changePasswordError = res.error;
			}
		},
		
		async onGet2faData(){
			let res = await this.authModel.doGet2faData();
			
			if(res.success){
				this.enable2faQr = res.qrdata;
				this.enable2faSecret = res.secret;
			}
		},
		
		async onEnable2fa(){
			this.btnEnable2faDisabled = true;
			this.enable2faError = '';
			
			let res = await this.authModel.doEnable2fa(this.enable2faSecret, this.enable2faCode);
			
			this.btnEnable2faDisabled = false;
			if(res.success){
				this.authModel.userProfile.google2fa_secret = this.enable2faSecret;
				this.enable2faQr = '';
				this.enable2faSecret = '';
				this.enable2faCode = '';
			} else {
				this.enable2faError = res.error;
			}
		},
		
		async onDisable2fa(){
			if(confirm('Вы уверены?')){
				this.btnDisable2faDisabled = true;
				this.disable2faError = '';
				
				let res = await this.authModel.doDisable2fa();
				
				this.btnDisable2faDisabled = false;
				if(res.success){
					this.authModel.userProfile.google2fa_secret = null;
					await this.onGet2faData();
				} else {
					this.disable2faError = res.error;
				}
			}
		},
		
		async onAddKeychain(provider, type){
			await this.$Oauth.authenticate(provider, type).then(async (response) => {
				if(response.code){
					let res = await this.authModel.doAddKeychain(provider, response.code);
					if(res.success){
						let keychains = JSON.parse(JSON.stringify(await this.authModel.getKeychains())).list||[];
						this.authModel.userKeychains = keychains;
					} else {
						alert(res.error);
					}
				}
			}).catch((err) => {
				console.log(err);
			});
		},
		
		async onDeleteKeychain(provider, id){
			if(confirm('Вы уверены?')){
				await this.authModel.doDeleteKeychain(provider, id).then(async (response) => {
					if(response.success){
						let keychains = JSON.parse(JSON.stringify(await this.authModel.getKeychains())).list||[];
						this.authModel.userKeychains = keychains;
					} else {
						alert(response.error);
					}
				}).catch((err) => {
					console.log(err);
				});
			}
		},
		
		async onDeleteAccount(){
			if(confirm('Вы уверены?')){
				this.btnDeleteAccountDisabled = true;
				this.deleteAccountError = '';
				
				let res = await this.authModel.doDeleteAccount(this.deleteAccountCurrentPassword);
				
				this.btnDeleteAccountDisabled = false;
				if(res.success){
					this.deleteAccountCurrentPassword = '';
					this.$router.push('/logout');
				} else {
					this.deleteAccountError = res.error;
				}
			}
		},
		
		getProviderKeychain(provider){
			return this.authModel.userKeychains.find((elem) => elem.provider == provider);
		},
		
		splitrazr(text, razr, sep){
			return lib.splitrazr(text, razr, sep);
		},
		
		handleQuestionaryAvaFileUpload(){
			this.changeQuestionaryAva = this.$refs.avatar.files[0];
			let reader  = new FileReader();
			reader.addEventListener('load', function(){
				this.img = reader.result;
				
				const cropperModal = new Modal('#cropperModal', {});
				cropperModal.show();
			}.bind(this), false);
			if(this.changeQuestionaryAva){
				if(/\.(jpe?g|png|gif)$/i.test(this.changeQuestionaryAva.name)){
					reader.readAsDataURL(this.changeQuestionaryAva);
				} else {
					alert('Not supported file extension!');
					this.changeQuestionaryAva = null;
					this.$refs.avatar.value = null;
				}
			}
		},
		onCropperCrop(){
			const { coordinates, canvas, } = this.$refs.cropper.getResult();
			this.coordinates = coordinates;
			// You able to do different manipulations at a canvas
			// but there we just get a cropped image, that can be used 
			// as src for <img/> to preview result
			//console.log(canvas.toDataURL());
			
			this.changeQuestionaryAvaPreview = canvas.toDataURL();
		},
		onCropperFlip(x,y){
			this.$refs.cropper.flip(x,y);
		},
		onCropperRotate(angle){
			this.$refs.cropper.rotate(angle);
		},
		
		async getAuthHistory(){
			let authHistory = await this.authModel.getAuthHistory(this.authHistoryStart);
			for(let hist of authHistory.list){
				this.authHistory.push(hist);
			}
			this.authHistoryCount = authHistory.count;
			this.authHistoryStart += authHistory.list.length;
			
			this.authHistoryBtnHide = this.authHistory.length == this.authHistoryCount;
		},
		
		getOsImg(os){
			os = os ?? '';
			os = os.toLowerCase();
			if(lib.contains(os, ['android', 'windows', 'linux'], true)){
				return os;
			}
			if(lib.contains(os, ['apple', 'mac', 'ipad', 'iphone', 'ipod'], true)){
				return 'apple';
			}
			return 'unknown';
		},
		formatDate(date, format) {
			return moment(date).format(format);
		},
	},
	async mounted(){
		this.changeQuestionaryAvaPreview = this.authModel.userProfile.ava;
		this.changeQuestionarySname = this.authModel.userProfile.sname;
		this.changeQuestionaryFname = this.authModel.userProfile.fname;
		this.changeQuestionaryLname = this.authModel.userProfile.lname;
		this.changeQuestionaryGender = this.authModel.userProfile.gender;
		this.changeQuestionaryDateofbirth = this.authModel.userProfile.dateofbirth;
		
		const cropperModalEl = document.getElementById('cropperModal');
		cropperModalEl.addEventListener('shown.bs.modal', event => {
			this.$refs.cropper.refresh();
			
			this.changeQuestionaryAva = null;
			this.$refs.avatar.value = null;
		});
		
		if(this.authModel.userProfile.google2fa_secret == null){
			await this.onGet2faData();
		}
		
		await this.getAuthHistory();
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		//Navbar,
		Cropper,
	},
};
</script>