import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout.vue';

import Login from '@/pages/Login.vue';
import LoginOut from '@/pages/LoginOut.vue';
import Register from '@/pages/Register.vue';
import RecoveryPasswordStep1 from '@/pages/RecoveryPasswordStep1.vue';
import RecoveryPasswordStep2 from '@/pages/RecoveryPasswordStep2.vue';
import ConfirmEmailStep1 from '@/pages/ConfirmEmailStep1.vue';
import ConfirmEmailStep2 from '@/pages/ConfirmEmailStep2.vue';
import Settings from '@/pages/Settings.vue';
import InfoAbout from '@/pages/InfoAbout.vue';
import InfoTerms from '@/pages/InfoTerms.vue';
import InfoTermsEn from '@/pages/InfoTermsEn.vue';
import InfoPrivacy from '@/pages/InfoPrivacy.vue';
import Feedback from '@/pages/Feedback.vue';

import Test1 from '@/pages/Test1.vue';
import Test2 from '@/pages/Test2.vue';

import PageNotFound from '@/pages/PageNotFound.vue';

const AuthComponent = {
	template: '<div class="auth-component"></div>',
};

const routes = [
	/*{
		path: '/',
		redirect: '/index',
	},*/
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '/',
				redirect: '/settings',
			},
			{
				path: '/login',
				component: Login,
				meta: {
					guest: true,
				},
			},
			{
				path: '/login-out',
				component: LoginOut,
				meta: {
					guest: true,
				},
			},
			{
				path: '/register',
				component: Register,
				meta: {
					guest: true,
				},
			},
			{
				path: '/recovery-password',
				component: RecoveryPasswordStep1,
				meta: {
					guest: true,
				},
			},
			{
				path: '/recovery-password-next',
				component: RecoveryPasswordStep2,
				meta: {
					guest: true,
				},
			},
			{
				path: '/confirm-email',
				component: ConfirmEmailStep1,
				meta: {
					guest: true,
				},
			},
			{
				path: '/confirm-email-next',
				component: ConfirmEmailStep2,
			},
			{
				path: '/settings',
				component: Settings,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/info/about',
				component: InfoAbout,
			},
			{
				path: '/info/terms',
				component: InfoTerms,
			},
			{
				path: '/info/privacy',
				component: InfoPrivacy,
			},
			{
				path: '/feedback',
				component: Feedback,
			},
			
			{
				path: '/test1',
				component: Test1,
			},
			{
				path: '/test2',
				component: Test2,
			},
			
			{
				path: '/:pathMatch(.*)*',
				component: PageNotFound,
			},
			{
				path: '/:pathMatch(.*)',
				component: PageNotFound,
			},
		],
	},
	{
		path: '/logout',
		beforeEnter(to, from, next){
			storeInstance.state.app.auth.doLogout().then(() => {
				next({path: '/login'});
			});
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				next({
					path: '/login',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				//let user = lib.localStorageGet('user')||{};
				/*if(to.matched.some(record => record.meta.is_admin)){
					if(user.is_admin){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}*/
				next()
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next() 
		}
	});
});
/*router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.userProfile !== null) next({path: '/'})
  	else next()
})*/

export default router;