const Discord = {
	name: 'discord',
	url: '/auth/discord',
	authorizationEndpoint: 'https://discord.com/oauth2/authorize',
	redirectUri: window.location.origin,
	responseMode: ['form_post'],
	requiredUrlParams: ['scope'],
	scope: ['identify', 'email'],
	scopeDelimiter: '%20',
	display: 'popup',
	oauthType: '2.0',
	popupOptions: {
		width: 500,
		height: 400
	}
}
const Mailru = {
	name: 'mailru',
	url: '/auth/mailru',
	authorizationEndpoint: 'https://oauth.mail.ru/login',
	redirectUri: window.location.origin,
	responseMode: ['form_post'],
	requiredUrlParams: ['state', 'scope'],
	scope: ['userinfo'],
	scopeDelimiter: ',',
	state: 'STATE',
	display: 'popup',
	oauthType: '2.0',
	popupOptions: {
		width: 580,
		height: 400
	}
}
const Twitch = {
	name: 'twitch',
	url: '/auth/twitch',
	authorizationEndpoint: 'https://id.twitch.tv/oauth2/authorize',
	redirectUri: window.location.origin,
	responseMode: ['form_post'],
	requiredUrlParams: ['scope'],
	scope: ['user:read:email'],
	scopeDelimiter: '+',
	display: 'popup',
	oauthType: '2.0',
	popupOptions: {
		width: 500,
		height: 400
	}
}
const Yandex = {
	name: 'yandex',
	url: '/auth/yandex',
	authorizationEndpoint: 'https://oauth.yandex.ru/authorize',
	redirectUri: window.location.origin,
	responseMode: ['form_post'],
	requiredUrlParams: ['scope'],
	scope: ['login:email', 'login:info', 'login:avatar'],
	scopeDelimiter: ' ',
	display: 'popup',
	oauthType: '2.0',
	popupOptions: {
		width: 500,
		height: 400
	}
}
export { Discord, Mailru, Twitch, Yandex };
