<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="text-center mt-2">
						<h5>{{$t('header-confirm-email')}}</h5>
					</div>
					<div class="p-2 mt-4">
						<form @submit.prevent="onConfirmEmailStep2">
							<div class="mb-3">
								<label class="form-label" for="username">{{$t('label-username')}}</label>
								<input
									type="text"
									class="form-control"
									id="username"
									v-model="username"
									autocomplete="username"
									required
								/>
							</div>
							<div class="mb-3">
								<label class="form-label" for="code">{{$t('label-confirm-email-code')}}</label>
								<input
									type="text"
									class="form-control"
									id="code"
									v-model="code"
									required
								/>
							</div>
							<div class="mt-3 text-end d-grid">
								<router-link :to="{path: '/confirm-email', query: {username}}" class="btn btn-secondary">{{$t('btn-resend-code')}}</router-link>
							</div>
							<div class="mt-3 text-end d-grid">
								<button class="btn btn-lg btn-primary" type="submit" :disabled="btnConfirmEmailDisabled">{{$t('btn-confirm-email')}}</button>
							</div>
						</form>
						<div class="alert alert-warning text-center mt-3 mb-0" v-if="error">{{error}}</div>
					</div>
				</div>
			</div>
			<div class="card shadow-none">
				<div class="card-body p-4 text-center">
					{{$t('text-have-account')}} <router-link :to="{path: '/login'}">{{$t('btn-login')}}</router-link>
				</div>
			</div>
			<div class="card shadow-none">
				<div class="card-body p-4 text-center">
					{{$t('text-dont-have-account')}} <router-link :to="{path: '/register'}">{{$t('text-register')}}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Подтверждение email | byLex Account'});
	},
	data: () => ({
		username: '',
		code: '',
		
		error: '',
		btnConfirmEmailDisabled: false,
	}),
	methods: {
		async onConfirmEmailStep2(){
			this.btnConfirmEmailDisabled = true;
			this.error = '';
			
			let res = await this.authModel.doConfirmEmailStep2({
				username: this.username,
				code: this.code,
			});
			
			// если пользователь найден
			if (res && res.success){
				document.location = this.$route.query.from != undefined ? this.$route.query.from : '/login';
			} else {
				// ошибка
				this.error = res.error;
			}
			
			this.btnConfirmEmailDisabled = false;
		},
	},
	mounted(){
		this.username = this.$route.query.username || '';
		this.code = this.$route.query.code || '';
		
		if(this.username && this.code){
			this.onConfirmEmailStep2();
		}
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {},
};
</script>