import axios from 'axios';
import lib from '@/lib';

export default class AuthModel
{
	/**
	 * Конструктор
	 */
	constructor()
	{
		// Инициализация
		this.authChecked = false;
		this.token = lib.localStorageGet('token')||'';
		//this.token = lib.getCookie('bylex_jwt')||'';
		
		this.userProfile = null;
		this.userPermissions = [];
		this.userKeychains = [];
		this.userAccounts = [];
	}
	
	/**
	 * Проверка на включенность двухфакторной аутентификации у пользователя
	 */
	async doCheck2fa(username)
	{
		return await fetch('/api/auth/check-2fa?' + new URLSearchParams({
			lang: storeInstance.state.app.getLang(),
			username,
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then((data) => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Авторизация
	 */
	async doLogin(username, password, google2fa_code)
	{
		return await fetch('/api/auth/login', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				username,
				password,
				google2fa_code,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then((data) => {
			//console.log(data);
			if(data.error && data.error != ''){
				console.log(data.error||'');
				this.userProfile = null;
				this.userPermissions = [];
				this.userKeychains = [];
				this.userAccounts = [];
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return {
					success: false,
					code: data.code,
					error: data.error,
				};
			}
			let token = data.token;
			//user.accessToken = token;
			lib.localStorageSet('token', token);
			this.token = token;
			//axios.defaults.headers.common['Authorization'] = token;
			this.setUserProfile(data.user_data);
			//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
			this.userPermissions = data.user_permissions||[];
			this.userKeychains = data.user_keychains||[];
			this.userAccounts = data.user_accounts||[];
			this.authChecked = true;
			return {
				success: true,
			};
		}).catch(error => {
			console.log(error);
			this.userProfile = null;
			this.userPermissions = [];
			this.userKeychains = [];
			this.userAccounts = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Авторизация через соцсети
	 */
	async socialLogin(provider, code)
	{
		return await fetch('/api/social-auth/' + provider + '/callback', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				code,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then((data) => {
			//console.log(data);
			if(data.error && data.error != ''){
				console.log(data.error||'');
				this.userProfile = null;
				this.userPermissions = [];
				this.userKeychains = [];
				this.userAccounts = [];
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return {
					success: false,
					error: data.error,
				};
			}
			let token = data.token;
			//user.accessToken = token;
			lib.localStorageSet('token', token);
			this.token = token;
			//axios.defaults.headers.common['Authorization'] = token;
			this.setUserProfile(data.user_data);
			//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
			this.userPermissions = data.user_permissions||[];
			this.userKeychains = data.user_keychains||[];
			this.userAccounts = data.user_accounts||[];
			this.authChecked = true;
			return {
				success: true,
			};
		}).catch(error => {
			console.log(error);
			this.userProfile = null;
			this.userPermissions = [];
			this.userKeychains = [];
			this.userAccounts = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Деавторизация
	 */
	async doLogout()
	{
		return await fetch('/api/auth/logout?' + new URLSearchParams({
			token: this.token,
		})).then(stream => stream.json()).then(data => {
			//console.log(data);
			this.userProfile = null;
			this.userPermissions = [];
			this.userKeychains = [];
			this.userAccounts = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return true;
		}).catch(error => {
			console.log(error);
			this.userProfile = null;
			this.userPermissions = [];
			this.userKeychains = [];
			this.userAccounts = [];
			lib.localStorageRemove('token');
			this.token = '';
			//delete axios.defaults.headers.common['Authorization'];
			this.authChecked = false;
			return false;
		});
	}
	
	/**
	 * Проверка на авторизованность
	 */
	async doCheckAuth()
	{
		if(!this.authChecked){
			return await fetch('/api/auth/checkauth?' + new URLSearchParams({
				token: this.token,
			})).then(stream => stream.json()).then(async (data) => {
				//console.log(data);
				//console.log('auth check done');
				if(!data.auth){
					// session expired or not exists. logout
					this.userProfile = null;
					this.userPermissions = [];
					this.userKeychains = [];
					this.userAccounts = [];
					lib.localStorageRemove('token');
					this.token = '';
					this.authChecked = true;
					return true;
				}
				let token = data.token;
				//user.accessToken = token;
				lib.localStorageSet('token', token);
				this.token = token;
				//axios.defaults.headers.common['Authorization'] = token;
				this.setUserProfile(data.user_data);
				//storeInstance.state.app.setLang((this.userSettings||{}).lang||'ru');
				this.userPermissions = data.user_permissions||[];
				this.userKeychains = data.user_keychains||[];
				this.userAccounts = data.user_accounts||[];
				this.authChecked = true;
				return true;
			}).catch(error => {
				console.log(error);
				this.userProfile = null;
				this.userPermissions = [];
				this.userKeychains = [];
				this.userAccounts = [];
				lib.localStorageRemove('token');
				this.token = '';
				//delete axios.defaults.headers.common['Authorization'];
				this.authChecked = false;
				return false;
			});
		} else {
			return true;
		}
	}
	
	/**
	 * Регистрация пользователя через api
	 * Возращает объект с результатом запроса
	 */
	async doRegister(params)
	{
		return await fetch('/api/auth/register', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				login: params.login,
				email: params.email,
				password1: params.password1,
				password2: params.password2,
				captcha: params.captcha || '',
				captcha_type: params.captcha_type || '',
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Восстановление пароля через api
	 * шаг 1
	 */
	async doRecoveryPasswordStep1(params)
	{
		return await fetch('/api/auth/recovery-password-step1', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				username: params.username || '',
				captcha: params.captcha || '',
				captcha_type: params.captcha_type || '',
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Восстановление пароля через api
	 * шаг 2
	 */
	async doRecoveryPasswordStep2(params)
	{
		return await fetch('/api/auth/recovery-password-step2', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				username: params.username || '',
				code: params.code || '',
				password1: params.password1 || '',
				password2: params.password2 || '',
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Подтверждение email через api
	 * шаг 1
	 */
	async doConfirmEmailStep1(params)
	{
		return await fetch('/api/auth/confirm-email-step1', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				username: params.username || '',
				captcha: params.captcha || '',
				captcha_type: params.captcha_type || '',
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Подтверждение email через api
	 * шаг 2
	 */
	async doConfirmEmailStep2(params)
	{
		return await fetch('/api/auth/confirm-email-step2', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				username: params.username || '',
				code: params.code || '',
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Изменение анкетных данных через api
	 * ava - новая аватарка
	 * sname - фамилия
	 * fname - имя
	 * lname - отчество
	 * gender - пол
	 * dateofbirth - дата рождения
	 */
	async doChangeQuestionary(ava, sname, fname, lname, gender, dateofbirth)
	{
		let params = {
			lang: storeInstance.state.app.getLang(),
			sname,
			fname,
			lname,
			gender,
			dateofbirth,
		};
		if(ava != this.getAvatarLink()){
			params['ava'] = ava;
		}
		return await fetch('/api/user/change-questionary', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify(params),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Смена логина через api
	 * login - новый логин
	 */
	async doChangeLogin(login)
	{
		return await fetch('/api/user/change-login', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				login,
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Смена email через api
	 * email - новый email
	 */
	async doChangeEmail(email)
	{
		return await fetch('/api/user/change-email', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				email,
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Смена пароля через api
	 * password1 - новый пароль
	 * password2 - повтор нового пароля
	 */
	async doChangePassword(password1, password2)
	{
		return await fetch('/api/user/change-password', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				password1,
				password2,
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение данных для включения двухфакторной аутентификации через api
	 */
	async doGet2faData()
	{
		return await fetch('/api/user/get-2fa?' + new URLSearchParams({
			lang: storeInstance.state.app.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Включение двухфакторной аутентификации через api
	 * secret - secret key
	 * code - код из приложения-аутентификатора
	 */
	async doEnable2fa(secret, code)
	{
		return await fetch('/api/user/enable-2fa', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				secret,
				code,
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Выключение двухфакторной аутентификации через api
	 */
	async doDisable2fa()
	{
		return await fetch('/api/user/disable-2fa', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение соцсетей из ключницы
	 */
	async getKeychains()
	{
		return await fetch('/api/user/keychain?' + new URLSearchParams({
			lang: storeInstance.state.app.getLang(),
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Добавление соцсети в ключницу
	 */
	async doAddKeychain(provider, code)
	{
		return await fetch('/api/user/keychain/' + provider, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				code,
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление соцсети из ключницы
	 */
	async doDeleteKeychain(provider, keychain_id)
	{
		return await fetch('/api/user/keychain/' + provider + '/' + keychain_id, {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'DELETE',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Получение истории авторизаций пользователя
	 */
	async getAuthHistory(start)
	{
		return await fetch('/api/user/auth-history?' + new URLSearchParams({
			lang: storeInstance.state.app.getLang(),
			start,
			limit: 10,
		}), {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'GET',
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	/**
	 * Удаление аккаунта через api
	 * password - текущий пароль
	 */
	async doDeleteAccount(password)
	{
		return await fetch('/api/user/delete-account', {
			mode: 'cors',
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'include', // include, *same-origin, omit
			method: 'POST',
			body: JSON.stringify({
				lang: storeInstance.state.app.getLang(),
				password,
			}),
			headers: {
				Authorization: 'Bearer '+this.token,
				'Content-Type': 'application/json',
			},
		}).then(stream => stream.json()).then(data => {
			//console.log(data);
			return data;
		}).catch(error => {
			//console.log(error);
			return {
				success: false,
				error,
			};
		});
	}
	
	isAuth()
	{
		return this.userProfile != null;
	}
	
	getLogin()
	{
		return this.userProfile != null ? this.userProfile.login : '';
	}
	
	getUserId()
	{
		return this.userProfile != null ? this.userProfile.user_id : '';
	}
	
	getAvatarLink()
	{
		return this.userProfile != null ? this.userProfile.ava : '';
	}
	
	hasPermission(permission_api_name, virtualspace_id)
	{
		virtualspace_id = virtualspace_id || null;
		return !!(this.userPermissions.length > 0 ? Object.values(this.userPermissions||[]).find((elem) => elem.virtualspace_id == virtualspace_id && elem.permissions.includes(permission_api_name)) : false);
	}
	
	/**
	 * Устанавливает user profile
	 */
	setUserProfile(res)
	{
		if(res == null){
			this.userProfile = null;
		} else {
			this.userProfile = {
				user_id: res.id,
				login: res.login,
				sname: res.sname,
				fname: res.fname,
				lname: res.lname,
				gender: res.gender,
				dateofbirth: res.dateofbirth,
				email: res.email,
				ava: res.avatar,
				google2fa_secret: res.google2fa_secret,
			};
		}
	}
}
