<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-12 col-lg-10 col-xl-8">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="mt-2">
						<h4>Пользовательское соглашение (далее соглашение)</h4>
					</div>
					<div class="mt-4">
						<div class="row">
							<div class="col">
								<p>Настоящий документ «Пользовательское соглашение» представляет собой предложение ИП «byLex» (далее — «byLex») заключить договор на изложенных ниже условиях.</p>
							</div>
							<div class="col text-end">
								<p>ИП «byLex»<br/>Введено в действие 18.12.2022 г</p>
							</div>
						</div>
					</div>
					<div class="mt-5">
						<ol>
							<li>
								<h5 class="mb-4 text-dark" id="1">Общие положения</h5>
								<ol>
									<li>
										<p id="1.1">В настоящем документе и вытекающих или связанных с ним отношениях Сторон применяются следующие термины и определения:</p>
										<ol>
											<li id="1.1.a"><strong>Соглашение</strong> — настоящий документ со всеми дополнениями, изменениями и указанными в нем обязательными документами, а также договор, заключенный на его основании.</li>
											<li id="1.1.b"><strong>Пользователь</strong> — дееспособное физическое лицо старше 18 лет, присоединившееся к настоящему Соглашению в собственном интересе либо выступающее от имени и в интересах представляемого им юридического лица.</li>
											<li id="1.1.c"><strong>Сайт / Сайты</strong> — любая из автоматизированных информационных систем, доступных в сети Интернет по сетевым адресам в следующих доменах (включая поддомены, если в отношении поддоменов не заключаются отдельные соглашения): <a href="https://bsrv.su">bsrv.su</a>, <a href="https://platform.bsrv.su">platform.bsrv.su</a>, <a href="https://id.bylex.su">id.bylex.su</a>.</li>
											<li id="1.1.d"><strong>Мобильное приложение</strong> — предназначенная для установки и использования на Устройстве программа для ЭВМ, позволяющая Пользователю получить доступ к Сервису с использованием сети связи.</li>
											<li id="1.1.e"><strong>Приложение</strong> — программы для ЭВМ и/или базы данных, в том числе Сайт и Мобильное приложение, предназначенные для предоставления доступа к Сервису с использованием Устройства в информационных целях.</li>
											<li id="1.1.f"><strong>Устройство</strong> — персональный компьютер, планшет, мобильный телефон, коммуникатор, смартфон, иное устройство, позволяющее использовать Приложение и/или Сервис по их функциональному назначению.</li>
											<li id="1.1.g"><strong>Сервис</strong> — комплекс услуг, предоставляемых Пользователю с использованием Приложения.</li>
											<li id="1.1.h"><strong>Контент</strong> — изображения, текстовые, аудио- и видеоматериалы, а также прочие объекты авторских и (или) смежных прав, а равно не являющиеся таковыми информация и сообщения любого характера.</li>
											<li id="1.1.i"><strong>byLex Account</strong> — единая система регистрации и авторизации в Приложениях с использованием идентификатора, присваиваемого Пользователю byLex'ом или в иных информационных системах, определяемых по единоличному усмотрению byLex'а.</li>
											<li id="1.1.j"><strong>Личный кабинет</strong> — персональный раздел Приложения, к которому Пользователь получает доступ после прохождения регистрации и/или авторизации в Приложении. Личный кабинет предназначен для хранения персональных данных Пользователя, создания профиля Пользователя, а также просмотра и управления иными доступными функциональными возможностями Приложения и соответствующими условиями использования Приложения.</li>
										</ol>
									</li>
									<li id="1.2">В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в п.1.1. Соглашения. В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае отсутствия однозначного толкования термина или определения в тексте Соглашения и иных документов, образующих договор на условиях Соглашения, следует руководствоваться его толкованием, определенным: в первую очередь — законодательством Российской Федерации, и в последующем — обычаями делового оборота и научной доктриной.</li>
									<li>
										<p id="1.3">Использование вами Приложения и/или предоставляемого на его основе Сервиса любым способом и в любой форме в пределах их объявленных функциональных возможностей и назначения, включая:</p>
										<ol>
											<li id="1.3.a">регистрацию и/или авторизацию в Приложении с использованием byLex Account;</li>
											<li id="1.3.b">просмотр размещенных в Приложении Материалов;</li>
											<li id="1.3.c">размещение или отображение в Приложении любого Контента;</li>
											<li id="1.3.d">иное использование функциональных возможностей Приложения или предоставляемого Сервиса;</li>
										</ol>
										— создает договор на условиях настоящего Соглашения в соответствии с положениями ст.ст.428, 437 и 438 Гражданского кодекса Российской Федерации.
									</li>
									<li id="1.4">Настоящим Соглашением и указанными в нем обязательными документами определяются основные условия использования Приложений, а также любое их развитие и/ или добавление новых функциональных возможностей.</li>
									<li>
										<p id="1.5">Обязательным условием использования Приложения и предоставления на его основе Сервиса является полное и безоговорочное принятие Пользователем условий следующих документов (по тексту Соглашения — «обязательные документы»):</p>
										<ol>
											<li id="1.5.a">Правил Сайта и одноименного Мобильного приложения, размещенных на страницах указанного Сайта и регулирующих порядок использования функциональных возможностей Сайта и связанного с ним Приложения;</li>
											<li id="1.5.b">Политики конфиденциальности, размещенной и/или доступной в сети Интернет по адресу <a href="https://id.bylex.su/info/privacy">https://id.bylex.su/info/privacy</a>. </li>
										</ol>
									</li>
									<li id="1.6">Предоставление Сервисов регулируется в соответствии с положениями настоящего Соглашения, а также дополнительно принимаемых на его основе документов (по тексту Соглашения — «дополнительный документ»), которые опубликованы byLex'ом на страницах относящихся к ним Сайтов и могут регулировать особенности использования определенного Сервиса.</li>
									<li id="1.7">В случае противоречия между Соглашением и условиями использования какого-либо из Сервисов в отдельности, изложенными в дополнительном документе, приоритет имеют условия использования соответствующего Сервиса, закрепленные в относящемся к нему дополнительном документе.</li>
									<li id="1.8">Соглашение, включая относящиеся к нему обязательные документы, может быть изменено byLex'ом без какого-либо специального уведомления. Новая редакция Соглашения и/или указанных в нем обязательных документов вступает в силу с момента размещения на Сайте либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения и/или указанных в нем обязательных документов.</li>
									<li>
										<p id="1.9">Воспользовавшись любой из указанных в п.1.3. возможностей вы подтверждаете, что:</p>
										<ol>
											<li id="1.9.a">Достигли возраста 18 лет;</li>
											<li id="1.9.b">Ознакомились с условиями настоящего Соглашения и указанных в нем обязательных для Сторон документов в полном объеме до начала использования Приложения и/или предоставляемого на его основе Сервиса;</li>
											<li id="1.9.c">Принимаете все условия настоящего Соглашения и указанных в нем обязательных для Сторон документов в полном объеме без каких-либо изъятий и ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование Сервиса. Если вы не согласны с условиями настоящего Соглашения и указанных в нем обязательных для Сторон документов или не имеете права на заключение договора на их основе, вам следует незамедлительно прекратить любое использование Приложения и предоставляемого на его основе Сервиса.</li>
										</ol>
									</li>
									<li id="1.10">Действующая редакция Соглашения опубликована на странице по адресу <a href="https://id.bylex.su/info/terms">https://id.bylex.su/info/terms</a>.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="2">Общие условия пользования Приложением</h5>
								<ol>
									<li id="2.1">Просмотр Контента, размещенного в Приложении в открытом доступе, не требует обязательной регистрации и/или авторизации Пользователя.</li>
									<li id="2.2">Любое иное применение функциональных возможностей Приложения, включая использование Сервисов, допускается только после прохождения Пользователем регистрации и авторизации в Приложении в соответствии с установленными byLex'ом правилами.</li>
									<li id="2.3">Перечень функциональных возможностей Приложения, использование которых требует предварительной регистрации и/или авторизации, а также принятия в необходимых случаях дополнительных документов на использование Сервисов, определяется по единоличному усмотрению byLex'а и может время от времени изменяться.</li>
									<li id="2.4">По завершении процедуры регистрации с использованием byLex Account создается уникальная учетная запись, связанная с Личным кабинетом Пользователя в Приложении, которая необходима для использования большинства функциональных возможностей Приложений и основанных на них Сервисов без отдельной регистрации Пользователя в каком-либо из Приложении в отдельности.</li>
									<li id="2.5">Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по вопросам, предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Пользователь предоставляет неверную информацию или у byLex'а есть основания полагать, что предоставленная Пользователем информация неполна или недостоверна, byLex имеет право по своему усмотрению заблокировать либо удалить учетную запись Пользователя, а также отказать Пользователю в использовании Приложений и связанных с ними Сервисов полностью или в определенной части.</li>
									<li id="2.6">byLex оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных, указанных при регистрации, и запросить в связи с этим подтверждающие документы (в частности — документы, удостоверяющие личность), непредоставление которых, по усмотрению byLex'а, может быть приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные п. 2.5. Соглашения. В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют идентифицировать пользователя, byLex вправе применить меры, указанные в п.2.5. Соглашения.</li>
									<li id="2.7">Информация о Пользователе, содержащаяся в учетной записи и Личном кабинете Пользователя, хранится и обрабатывается byLex'ом в соответствии Политикой конфиденциальности.</li>
									<li id="2.8">При регистрации Пользователь самостоятельно выбирает себе логин (уникальное символьное имя учетной записи Пользователя) и пароль для доступа к Личному кабинету. Последующее изменение пароля Пользователя осуществляется с использованием программных средств Приложения, предоставляемых в его Личном кабинете. byLex вправе запретить использование определенных логинов, а также устанавливать требования к логину и паролю (длина, допустимые символы и т.д.).</li>
									<li id="2.9">Зарегистрированный Пользователь самостоятельно определяет порядок использования Личного кабинета и иных функциональных возможностей Приложения, включая условия использования соответствующего Сервиса, которые однако ни при каких условиях не могут противоречить настоящему Соглашению за изъятиями, установленными в дополнительных документах.</li>
									<li id="2.10">С использованием функциональных возможностей Приложения Пользователь может самостоятельно, своими действиями, по своему усмотрению, раскрывать неопределенному кругу лиц (публиковать в Сервисе) информацию о себе, которая может включать персональные данные.
									</li>
									<li id="2.11">Порядок и последствия использования логина и пароля Пользователем определяются в разделе 6 настоящего Соглашения.
									</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="3">Ограничения в пользовании Приложением</h5>
								<p>Принимая условия настоящего Соглашения, вы понимаете и признаете, что:</p>
								<ol>
									<li id="3.1">byLex вправе устанавливать лимиты и ограничения в использовании Приложений и основанных на них Сервисов для всех Пользователей, либо для отдельных категорий Пользователей (в зависимости от места пребывания Пользователя, языка, на котором предоставляется Сервис, и т.д.), в том числе: наличие/отсутствие отдельных функций, максимальный срок хранения информации и данных, специальные параметры загружаемой информации и т.д.</li>
									<li id="3.2">Сведения об установленных ограничениях будут доводиться до сведения Пользователей в форме и способом, которые определяются по единоличному усмотрению byLex'а.</li>
									<li>
										<p id="3.3">Если иное не предусмотрено дополнительными документами на использование отдельного Сервиса:</p>
										<ol>
											<li id="3.3.a">Пользователь использует Приложение на свой собственный риск. Сервисы предоставляются «как есть». byLex не принимает на себя никакой ответственности, в том числе за соответствие Приложения и основанного на нем Сервиса целям Пользователя;</li>
											<li id="3.3.b">byLex не гарантирует, что: Приложения и/или Сервисы соответствуют требованиям Пользователя на момент принятия Соглашения и будут соответствовать им впоследствии; Сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые могут быть получены с использованием Приложений, будут точными и надежными и могут использоваться для каких-либо целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо фактов); качество какого-либо продукта, услуги, информации и Контента, полученных с использованием Сервисов, будет соответствовать ожиданиям Пользователя;</li>
											<li id="3.3.c">Любые информация и/или материалы (в том числе загружаемое ПО, письма, какие- либо инструкции и руководства к действию и т.д.), доступ к которым Пользователь получает с использованием Приложений, Пользователь может использовать на свой собственный риск и самостоятельно несет ответственность за возможные последствия использования указанных информации и/или материалов, в том числе за ущерб, который это может причинить компьютеру Пользователя или третьим лицам, за потерю данных или любой другой вред;</li>
											<li id="3.3.d">Поскольку Приложения и основанные на них Сервисы находятся на стадии постоянного дополнения и обновления, форма и характер функциональных возможностей Приложений и предоставляемых Сервисов могут время от времени меняться без предварительного уведомления Пользователя. byLex вправе при необходимости по собственному усмотрению прекратить (временно или окончательно) предоставление Сервисов (или каких-либо отдельных функций в рамках Сервисов) всем Пользователям вообще или отдельному Пользователю, в частности, без предварительного уведомления;</li>
											<li id="3.3.e">byLex не несет ответственности за любые виды убытков, произошедшие вследствие использования Пользователем Приложений и/или основанных на них Сервисов;</li>
											<li id="3.3.f">При любых обстоятельствах ответственность byLex'а в соответствии со статьей 15 Гражданского кодекса России ограничена 20 000 (двадцатью тысячами) рублей Российской Федерации и возлагается на него при наличии в его действиях вины.</li>
										</ol>
									</li>
									<li id="3.4">Если какое-то лицо зарегистрировалось в качестве Пользователя от имени уполномочившей на то компании, это означает, что компания принимает Соглашение и обязуется защищать byLex, его дочерние структуры, руководство, агентов и сотрудников от любых судебных исков, процессов и разбирательств, связанных с использованием Приложений и/или основанных на них Сервисов, а также от любой ответственности, в том числе финансовой, в отношении исков, ущерба, повреждения, процессов, разбирательств, судебных издержек и гонораров адвокатов.</li>
									<li id="3.5">byLex никак не связан с Контентом, предоставленным и/или размещенным (в том числе транслируемым) Пользователями в Приложении, и не осуществляет проверку содержания, подлинности и безопасности такого Контента либо его компонентов, а равно их соответствия требованиям действующего законодательства и наличия у Пользователей необходимого объема прав на его распространение и/или использование.</li>
									<li id="3.6">Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем Контента требованиям действующего законодательства, включая ответственность перед третьим лицами в случаях, когда размещение Пользователем такого Контента или его содержание нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/ или посягает на принадлежащие им нематериальные блага.</li>
									<li id="3.7">Пользователь соглашается с тем, что byLex не обязан осуществлять предварительную проверку Контента любого вида, размещаемого и/или распространяемого Пользователем посредством Приложений, а также то, что byLex имеет право (но не обязанность) по своему усмотрению отказать Пользователю в размещении и/или распространении им такого Контента или удалить любой Контент, который доступен посредством Приложений. Пользователь осознает и согласен с тем, что он должен самостоятельно оценивать все риски, связанные с размещением и распространением такого Контента, включая оценку надежности, полноты или полезности такового.</li>
									<li id="3.8">
										<p>В частности при использовании Приложений Пользователь не вправе:</p>
										<ol>
											<li id="3.8.a">загружать, посылать, передавать или любым другим способом размещать и/или распространять информацию, которая является незаконной, вредоносной, клеветнической, оскорбляет нравственность, демонстрирует (или является пропагандой) насилие и жестокость, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных услуг), разъясняет порядок изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых веществ или иного оружия;</li>
											<li id="3.8.b">нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в любой форме;</li>
											<li id="3.8.c">выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников byLex'а, а также применять любые другие формы и способы незаконного представительства других лиц в сети Интернет, а также вводить пользователей или byLex в заблуждение относительно свойств и характеристик каких- либо субъектов или объектов;</li>
											<li id="3.8.d">загружать, посылать, передавать или любым другим способом размещать и/или распространять Контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям;</li>
											<li id="3.8.e">загружать, посылать, передавать или любым другим способом размещать и/или распространять не разрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих адресов электронной почты, схемы «пирамид», многоуровневого (сетевого) маркетинга (MLM), системы интернет-заработка и e-mail- бизнесов, «письма счастья», а также использовать Приложения для участия в этих мероприятиях;</li>
											<li id="3.8.f">несанкционированно собирать и хранить персональные данные других лиц;</li>
											<li id="3.8.g">нарушать нормальную работу Приложений</li>
											<li id="3.8.h">размещать ссылки на ресурсы сети Интернет, содержание которых противоречит действующему законодательству Российской Федерации;</li>
											<li id="3.8.i">содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;</li>
											<li id="3.8.j">другим образом нарушать нормы законодательства, в том числе нормы международного права.</li>
										</ol>
									</li>
									<li id="3.9">В случае обнаружения нарушения прав и/или интересов в связи с использованием Приложений, в том числе размещением ненадлежащего Контента иным Пользователем, следует сообщить об этом byLex'у путем направления письменного уведомления с подробным изложением обстоятельств нарушения и гипертекстовой ссылкой на страницу Приложения, содержащую материалы, которыми нарушаются соответствующие права и/или интересы.</li>
									<li id="3.10">При наличии претензий третьих лиц в отношении нарушения любых имущественных и/ или личных неимущественных прав третьих лиц, а равно установленных законодательством запретов или ограничений определенным Пользователем, такой Пользователь обязан по требованию byLex'а пройти официальную идентификацию, предоставив byLex'у нотариально заверенное обязательство урегулировать возникшие претензии собственными силами и за свой счет с указанием своих паспортных данных.</li>
									<li id="3.11">В случае привлечения byLex'а к ответственности или наложения на него взыскания в связи с допущенными Пользователем нарушениями прав и/или интересов третьих лиц, а равно установленных законодательством запретов или ограничений, такой Пользователь обязан в полном объеме возместить убытки byLex'а.</li>
									<li id="3.12">При многократном или грубом нарушении условий настоящего Соглашения и/или требований законодательства, byLex вправе заблокировать или удалить учетную запись Пользователя, а также запретить доступ с использованием учетной записи к определенным Приложениям и/или Сервисам, и удалить любые указанные Пользователем в Личном кабинете сведения или размещенный им Контент без предварительного уведомления.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="4">Интеллектуальные права</h5>
								<ol>
									<li id="4.1">Все объекты, доступные при помощи Приложений, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты, размещенные в рамках Приложений, являются объектами исключительных прав byLex'а, Пользователей и других правообладателей.</li>
									<li id="4.2">byLex предоставляет Пользователю право функционального использования Приложения в пределах его общих функциональных возможностей.</li>
									<li id="4.3">Использование Приложения иными способами, в том числе путем копирования (воспроизведения) размещенного в Приложении Контента, а также входящих в состав Приложения элементов дизайна, программ для ЭВМ и баз данных, их декомпиляция, модификация, и последующее распространение, публичный показ, доведение до всеобщего сведения, строго запрещены, если иное не предусмотрено настоящим Соглашением.</li>
									<li id="4.4">Пользователь не вправе воспроизводить, повторять и копировать, продавать, а также использовать для каких-либо коммерческих целей какие-либо части Приложений (включая Контент, доступный Пользователю посредством Приложений), или доступ к ним, кроме тех случаев, когда Пользователь получил такое разрешение от byLex'а, либо когда это прямо предусмотрено дополнительными документами на использование отдельного Сервиса.</li>
									<li id="4.5">Использование Пользователем Приложений, а также размещенного в них Контента для личного некоммерческого использования допускается при условии сохранения всех знаков охраны авторского права, смежных прав, товарных знаков, других уведомлений об авторстве, сохранения имени (или псевдонима) автора/наименования правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном виде. Исключение составляют случаи, прямо предусмотренные законодательством Российской Федерации или дополнительными документами на использование отдельного Сервиса.</li>
									<li id="4.6">Приложения могут содержать ссылки на сайты в сети Интернет (сайты третьих лиц). Указанные третьи лица и их контент не проверяются byLex'ом на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.). byLex не несет ответственности за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ в связи с использованием Приложений, а также за доступность таких сайтов или информации и последствия их использования Пользователем.</li>
									<li id="4.7">Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная в рамках Приложений, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны byLex'а, за исключением случаев, когда на это прямо указывается byLex'ом.</li>
									<li id="4.8">
										<p>Принимая условия настоящего Соглашения, Пользователь безвозмездно предоставляет byLex'у простую(неисключительную) лицензию на использование Контента следующими способами:</p>
										<ol>
											<li id="4.8.a">воспроизводить Контент, т.е. совершать изготовление одного или более экземпляров Контента в любой материальной форме, а также его запись в память электронного устройства (право на воспроизведение);</li>
											<li id="4.8.b">распространять экземпляры Контента, т.е. предоставлять доступ к воспроизведенному в любой материальной форме Контенту, в том числе сетевыми и иными способами, а также путем продажи, проката, сдачи внаем, предоставления взаймы, включая импорт для любой из этих целей (право на распространение);</li>
											<li id="4.8.c">публично показывать Контент (право на публичный показ);</li>
											<li id="4.8.d">публично исполнять Контент (право на публичное исполнение);</li>
											<li id="4.8.e">сообщать Контент таким образом, при котором любое лицо может иметь доступ к нему в интерактивном режиме из любого места и в любое время по своему выбору (право на доведение до всеобщего сведения);</li>
											<li id="4.8.f">модифицировать Контент, т.е. переделывать или другим образом перерабатывать Контент, включая перевод Контента с одного языка на другой (право на переработку);</li>
											<li id="4.8.g">право переуступить все или часть полученных прав третьим лицам (право на сублицензирование).</li>
										</ol>
									</li>
									<li id="4.9">Указанная простая (неисключительная) лицензия на использование Контента предоставляется byLex'у одновременно с добавлением Контента в Приложение на весь срок действия исключительных прав на объекты авторских и (или) смежных прав, образующих такой Контент, для использования на территории всех стран мира.</li>
									<li id="4.10">Пользователь гарантирует наличие права на распоряжение Контентом на условиях вышеуказанной лицензии в необходимом объеме.
									</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="5">Уведомления</h5>
								<ol>
									<li id="5.1">byLex вправе посылать Пользователю на указанный в его Личном кабинете электронный адрес информационные электронные сообщения (далее — «нотификаторы») о важных событиях, происходящих в рамках Приложения или в связи с ним.</li>
									<li id="5.2">Помимо прочего допускается использование нотификаторов для информирования Пользователя об ограничениях доступа к Приложению и/или Сервису в связи с профилактическими работами, нарушениями Пользователя, изменении функционала Приложения, содержания или условий предоставления Сервиса, включая изменение Соглашения, обязательных и дополнительных документов.</li>
									<li id="5.3">byLex по собственному усмотрению вправе использовать нотификаторы для распространения рекламы собственных услуг и продукции (услуг) третьих лиц.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="6">Соглашение об использовании электронной подписи</h5>
								<ol>
									<li id="6.1">Во взаимоотношениях между byLex'ом и Пользователем могут использоваться электронные документы, удостоверенные простой электронной подписью.</li>
									<li id="6.2">Простой электронной подписью признается электронная подпись, которая посредством использования логина и пароля Пользователя или указанного в Личном кабинете адреса электронной почты Пользователя (ключ электронной подписи) подтверждает факт формирования электронной подписи непосредственно Пользователем.</li>
									<li id="6.3">По соглашению Сторон электронные документы, подписанные простой электронной подписью, признаются равнозначными документам на бумажных носителях, подписанным собственноручной подписью.</li>
									<li id="6.4">byLex определяет Пользователя, которому соответствует простая электронная подпись, по используемому Пользователем логину и паролю, указанным при регистрации/или авторизации в Приложении, — в случае совершения любых действий по использованию Сервиса, либо по используемому Пользователем адресу электронной почты — в случае поступления byLex'у сообщений с такого адреса.</li>
									<li id="6.5">Любые действия, совершенные с использованием простой электронной подписи определенного Пользователя, считаются совершенными таким Пользователем.</li>
									<li id="6.6">Оформление Пользователем заказа на предоставление доступа к Сервису с помощью программных средств в его Личном кабинете или путем направления byLex'у электронного письма с адреса, указанного в Личном кабинете, свидетельствует о подписании такого электронного документа простой электронной подписью Пользователя и подтверждает его намерение заключить договор на условиях, указанных в соответствующих дополнительных документах на использование такого Сервиса.</li>
									<li id="6.7">Пользователь обязуется соблюдать конфиденциальность ключа электронной подписи. В частности, Пользователь не имеет права передавать свои логин и пароль или предоставлять доступ к своей электронной почте третьим лицам, и несет полную ответственность за их сохранность и индивидуальное использование, самостоятельно выбирая способ их хранения и ограничения к ним доступа.</li>
									<li id="6.8">В случае несанкционированного доступа к логину и паролю, их утраты или раскрытия третьим лицам Пользователь обязан незамедлительно сообщить об этом byLex'у путем направления электронного письма с указанного в своем Личном кабинете адреса электронной почты.</li>
									<li id="6.9">В случае утраты или несанкционированного доступа к электронной почте, адрес которой указан в Личном кабинете, Пользователь обязан незамедлительно заменить такой адрес в Личном кабинете на новый и сообщить о данном факте byLex'у путем направления электронного письма с нового адреса электронной почты.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="7">Заключительные положения</h5>
								<ol>
									<li id="7.1">Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином «законодательство» понимается законодательство Российской Федерации.</li>
									<li id="7.2">Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.</li>
									<li id="7.3">Бездействие со стороны byLex'а в случае нарушения Пользователем либо иными Пользователями положений Соглашений не лишает byLex права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказ byLex'а от своих прав в случае совершения в последующем подобных либо сходных нарушений.</li>
									<li id="7.4">Все споры по Соглашению или в связи с ним подлежат рассмотрению, в зависимости от подведомственности: в Арбитражном суде г. Москвы или в Басманном районном суде г. Москвы в соответствии с процессуальным законодательством Российской Федерации.</li>
									<li id="7.5">Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть предоставлено Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и версии Соглашения на ином языке применяются положения русскоязычной версии настоящего Соглашения.</li>
								</ol>
							</li>
							<li>
								<h5 class="mb-4 text-dark" id="8">Реквизиты byLex'а:</h5>
								<p>
									ИП «byLex»<br/>
									Регистрационный номер: 1234567890<br/>
									Адрес: 010013, Казахстан, Астана, ул. Неизвестная, д. 3<br/>
									E-mail: <a href="mailto:lexin.ucoz.ru@gmail.com">lexin.ucoz.ru@gmail.com</a>
								</p>
							</li>
						</ol>
					</div>
					<!--
					<p><router-link :to="{path: '/info/terms_20200207'}">Редакция Соглашения от 07 февраля 2020 года.</router-link></p>
					<p><router-link :to="{path: '/info/terms_20181225'}">Редакция Соглашения от 25 декабря 2018 года.</router-link></p>
					<p><router-link :to="{path: '/info/terms_20170705'}">Редакция Соглашения от 05 июля 2017 года.</router-link></p>
					<p><router-link :to="{path: '/info/terms_20150615'}">Редакция Соглашения от 15 июня 2015 года.</router-link></p>
					<p><router-link :to="{path: '/info/terms_20141008'}">Редакция Соглашения от 8 октября 2014 года.</router-link></p>
					<p><router-link :to="{path: '/info/terms_20131002'}">Редакция Соглашения от 2 октября 2013 года.</router-link></p>
					-->
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
ol {
    counter-reset: list1;
    list-style: none;
    padding: 0 0 0 20px;
}
ol ol {
    counter-reset: list2;
    padding: 0 0 0 34px;
}
ol ol ol {
    counter-reset: list3;
    padding: 0 0 0 34px;
}

h5,
li {
    position: relative;
}
ol h5,
ol li {
    position: relative;
}
ol ol h5,
ol ol li {
    position: relative;
}
ol ol ol h5,
ol ol ol li {
    position: relative;
}

ol li {
    position: relative;
    margin: 0 0 36px;
}
ol li::before {
    content: counter(list1) ". ";
    counter-increment: list1;
    font-weight: 600;
    position: absolute;
    right: 100%;
    padding: 0 6px 0 0;
}

ol ol li {
    margin: 0 0 16px;
}
ol ol li::before {
    counter-increment: list2;
    content: counter(list1) "." counter(list2) ". ";
}

ol ol ol li {
    margin: 20px 0;
}
ol ol ol li::before {
    counter-increment: list3;
    content: counter(list1) "." counter(list2) "." counter(list3) ". ";
    padding: 0 8px 0 0;
	font-weight: 600;
}
ol ol ol li::before {
    counter-increment: list3;
    content: counter(list3, lower-latin) ". ";
    padding: 0 8px 0 0;
}

p:first-child {
    margin-top: 0;
}
p {
    margin-top: 16px;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Пользовательское соглашение | byLex Account'});
	},
	data: () => ({
		
	}),
	methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		//Navbar,
	},
};
</script>