<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div class="card shadow-none">
				<div class="card-body p-4">
					<div class="text-center mt-2">
						<h5>{{$t('header-confirm-email')}}</h5>
					</div>
					<div class="p-2 mt-4">
						<form @submit.prevent="onConfirmEmailStep1">
							<div class="mb-3">
								<label class="form-label" for="username">{{$t('label-username')}}</label>
								<input
									type="text"
									class="form-control"
									id="username"
									v-model="username"
									autocomplete="username"
									required
								/>
							</div>
							<div class="mb-3 text-center">
								<vue-hcaptcha :sitekey="appModel.hcaptcha_sitekey"></vue-hcaptcha>
							</div>
							<div class="mt-3 text-end d-grid">
								<button class="btn btn-lg btn-primary" type="submit" :disabled="btnConfirmEmailDisabled">{{$t('btn-send-code')}}</button>
							</div>
						</form>
						<div class="alert alert-warning text-center mt-3 mb-0" v-if="error">{{error}}</div>
					</div>
				</div>
			</div>
			<div class="card shadow-none">
				<div class="card-body p-4 text-center">
					{{$t('text-have-account')}} <router-link :to="{path: '/login'}">{{$t('btn-login')}}</router-link>
				</div>
			</div>
			<div class="card shadow-none">
				<div class="card-body p-4 text-center">
					{{$t('text-dont-have-account')}} <router-link :to="{path: '/register'}">{{$t('text-register')}}</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Подтверждение email | byLex Account'});
	},
	data: () => ({
		username: '',
		came_from: '',
		
		error: '',
		btnConfirmEmailDisabled: false,
	}),
	methods: {
		async onConfirmEmailStep1(){
			this.btnConfirmEmailDisabled = true;
			this.error = '';
			
			let hcaptcha = window['hcaptcha'] != null ? window['hcaptcha'].getResponse() : '';
			
			let res = await this.authModel.doConfirmEmailStep1({
				username: this.username,
				captcha: hcaptcha,
				captcha_type: 'hcaptcha',
			});
			
			// если пользователь найден
			if (res.success){
				if (this.came_from === ''){
					this.$router.push({
						path: '/confirm-email-next',
						query: {
							username: this.username,
						},
					});
				} else {
					this.$router.push({
						path: '/confirm-email-next',
						query: {
							from: this.came_from,
							username: this.username,
						},
					});
				}
			} else {
				// пользователь не найден
				this.error = res.error;
			}
			
			this.btnConfirmEmailDisabled = false;
			window['hcaptcha'].reset();
		},
	},
	mounted(){
		this.username = this.$route.query.username || '';
		this.came_from = this.$route.query.ref ? this.$route.query.ref : '';
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {
		VueHcaptcha,
	},
};
</script>