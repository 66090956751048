<template>
	<div class="row align-items-center justify-content-center">
		<div class="col-md-8 col-lg-6 col-xl-5">
			<div v-if="form == 'login'">
				<div class="card shadow-none">
					<div class="card-body p-4">
						<div class="text-center mt-2">
							<h5>{{$t('header-login')}}</h5>
						</div>
						<div class="p-2 mt-4">
							<form @submit.prevent="onLoginSubmit">
								<div class="mb-3">
									<label class="form-label" for="username">{{$t('label-username')}}</label>
									<input
										type="text"
										class="form-control font-monospace"
										id="username"
										ref="username"
										v-model="username"
										autocomplete="username"
										required
									/>
								</div>
								<div class="mb-3">
									<div class="float-end">
										<router-link :to="{path: '/recovery-password'}" class="text-muted">{{$t('text-lost-password')}}</router-link>
									</div>
									<label class="form-label" for="password">{{$t('label-password')}}</label>
									<div class="input-group">
										<input
											:type="type"
											class="form-control font-monospace"
											id="password"
											ref="password"
											v-model="password"
											autocomplete="current-password"
											required
										/>
										<button
											@click="buttonEye(), (clickedEye = !clickedEye)"
											class="btn btn-outline-secondary shadow-none"
											type="button"
										>
											<font-awesome-icon
												class="eyeButton"
												:icon="clickedEye ? 'eye-slash' : 'eye'"
											>
											</font-awesome-icon>
										</button>
									</div>
								</div>
								<div class="mt-3 d-grid">
									<button class="btn btn-lg btn-primary" type="submit" :disabled="btnLoginDisabled">{{$t('btn-login')}}</button>
								</div>
							</form>
							<div class="alert alert-warning text-center mt-3 mb-0" v-if="errorLogin">{{errorLogin}}</div>
							<div class="mt-3 d-grid" v-if="btnConfirmEmailShow">
								<router-link :to="{path: '/confirm-email', query: {username}}" class="btn btn-warning">{{$t('btn-confirm-email')}}</router-link>
							</div>
						</div>
					</div>
				</div>
				<div class="card shadow-none">
					<div class="card-body p-4 text-center">
						<h6>{{$t('header-login-other')}}</h6>
						<div class="pt-2">
							<button @click="onAuthProvider(provider.type, providerTypes[provider.type])" class="btn btn-secondary p-0 m-1" :class="['icon-hover-'+provider.icon]" :title="provider.name" v-for="provider in appModel.providers" :key="provider.type">
								<svg width="36" height="36">
									<use :xlink:href="'/assets/images/icons-sprite.svg#'+provider.icon"></use>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div class="card shadow-none">
					<div class="card-body p-4 text-center">
						{{$t('text-dont-have-account')}} <router-link :to="{path: '/register'}">{{$t('text-register')}}</router-link>
					</div>
				</div>
			</div>
			<div class="card shadow-none" v-if="form == '2fa'">
				<div class="card-body p-4">
					<div class="text-center mt-2">
						<h5>{{$t('header-2fa')}}</h5>
					</div>
					<div class="p-2 mt-4">
						<form @submit.prevent="onLoginSubmit">
							<div class="mb-3">
								<label class="form-label" for="google2fa_code">{{$t('label-2fa-code')}}</label>
								<input
									type="text"
									class="form-control font-monospace no-controls"
									id="google2fa_code"
									ref="google2fa_code"
									v-model="google2fa_code"
									autocomplete="off"
									:placeholder="$t('placeholder-2fa-code')"
									onwheel="return false"
									required
								/>
							</div>
							<div class="row mt-3">
								<div class="col-8 d-grid">
									<button class="btn btn-lg btn-primary" type="submit" :disabled="btn2faDisabled">{{$t('btn-verify')}}</button>
								</div>
								<div class="col-4 d-grid">
									<button class="btn btn-lg btn-outline-secondary" type="button" @click="onBack()" :disabled="btn2faDisabled">{{$t('btn-cancel')}}</button>
								</div>
							</div>
						</form>
						<div class="alert alert-warning text-center mt-3" v-if="error2fa">{{error2fa}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="sass" scoped>
input[type=number].no-controls::-webkit-outer-spin-button,
input[type=number].no-controls::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { Facebook, Google, Github, Twitter, Vkontakte, Live } from 'universal-social-auth';
import { Discord, Mailru, Twitch, Yandex } from '@/assets/plugins/socials';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Авторизация | byLex Account'});
	},
	data: () => ({
		username: '',
		password: '',
		google2fa_code: '',
		
		form: 'login',
		
		clickedEye: false,
		type: 'password',
		
		errorLogin: '',
		btnLoginDisabled: false,
		
		btnConfirmEmailShow: false,
		
		error2fa: '',
		btn2faDisabled: false,
		
		providerTypes: {
			facebook: Facebook,
			google: Google,
			github: Github,
			twitter: Twitter,
			vkontakte: Vkontakte,
			microsoft: Live,
			discord: Discord,
			mailru: Mailru,
			twitch: Twitch,
			yandex: Yandex,
		},
	}),
	methods: {
		async onLoginSubmit(){
			this.btnLoginDisabled = false;
			this.btnConfirmEmailShow = false;
			this.errorLogin = '';
			this.btn2faDisabled = false;
			this.error2fa = '';
			
			if(this.form == 'login'){
				this.btnLoginDisabled = true;
				
				let check2fa = await this.authModel.doCheck2fa(this.username);
				if(check2fa.success && check2fa.enable){
					this.btnLoginDisabled = false;
					this.form = '2fa';
					setTimeout(() => {
						this.$refs.google2fa_code.focus();
					}, 100);
				} else {
					let res = await this.authModel.doLogin(this.username, this.password, this.google2fa_code);
					
					this.btnLoginDisabled = false;
					if(res.success){
						this.$router.push('/');
					} else {
						this.errorLogin = res.error;
						this.btnConfirmEmailShow = (res.code == 1);
					}
				}
			} else if(this.form == '2fa'){
				this.btn2faDisabled = true;
				
				let res = await this.authModel.doLogin(this.username, this.password, this.google2fa_code);
				
				this.btn2faDisabled = false;
				if(res.success){
					this.$router.push('/');
				} else {
					this.error2fa = res.error;
				}
			}
		},
		
		async onAuthProvider(provider, type){
			this.errorLogin = '';
			this.error2fa = '';
			
			await this.$Oauth.authenticate(provider, type).then(async (response) => {
				if(response.code){
					let res = await this.authModel.socialLogin(provider, response.code);
					if(res.success){
						this.$router.push('/');
					} else {
						this.error = res.error;
					}
				}
			}).catch((err) => {
				console.log(err);
			});
		},
		
		buttonEye() {
			if (this.type === 'password'){
				this.type = 'text';
			} else {
				this.type = 'password';
			}
		},
		
		onBack(){
			this.btnLoginDisabled = false;
			this.btnConfirmEmailShow = false;
			this.errorLogin = '';
			this.btn2faDisabled = false;
			this.error2fa = '';
			this.form = 'login';
			setTimeout(() => {
				this.$refs.username.focus();
			}, 100);
		},
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	mounted(){
		this.$refs.username.focus();
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	computed: {},
	components: {},
};
</script>